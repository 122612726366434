import { method } from "lodash";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import { baseURL } from "Utils/baseUrl";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  setAddScreen: Dispatch<SetStateAction<boolean>>;
  addScreen: boolean;
  userName: string;
  domainIds: string;
  userId: string;
  setFormData: Dispatch<SetStateAction<{
    amount: string;
    utr: string;
    image: string;
}>>
formData: {
  amount: string;
  utr: string;
  image: string;
}
  getWellet: (image: any, user_id: string, parent_id: string, payment_method_id: string, amount: string, user_reference_no: string, remark: string, crdr: string) => Promise<void>
}

const AddScreenShot = ({
  setAddScreen,
  addScreen,
  userName,
  domainIds,
  userId,
  getWellet,
  setFormData,
  formData
}: Props) => {
  const [data, setData] = useState<any>([]);
  const [active, setActive] = useState<number>(0);
  const [bankDetails, setBankDetails] = useState<any>([]);
  const [methodId, setMethodId] = useState<string>("");
 
  const [formErrors, setFormErrors] = useState({
    amount: "",
    utr: "",
    image: "",
  });
  // const [touchedFields, setTouchedFields] = useState({
  //   amount: false,
  //   utr: false,
  //   image: false,
  // });

  const getPaymentMethod = async (
    user: string,
    domain_id: string,
    type: string
  ) => {
    const { response } = await authServices.getPaymentMethod({
      user_id: user,
      domain_id,
      type,
    });
    if (response) {
      setData(response?.data);
    }
  };
  

  const getParentPaymentDetails = async (
    user_id: string,
    domain_id: string,
    method_id: string
  ) => {
    const { response } = await authServices.getParentPaymentDetails({
      user_id,
      domain_id,
      method_id,
    });
    if (response) {
      setBankDetails(response?.data);
    }
  };

  useEffect(() => {
    if (userId && domainIds) getPaymentMethod(userId, domainIds, "DEPOSIT");
  }, [domainIds, userId]);

  const validateForm = (data = formData) => {
    let isValid = true;
    const errors: any = {};
  
    if (!data.amount || isNaN(Number(data.amount)) || Number(data.amount) < 100) {
      errors.amount = "Amount is required and must be at least 100.";
      isValid = false;
    } else {
      errors.amount = "";
    }
  
    const utrPattern = /^[A-Za-z0-9]{12,22}$/; 
    if (!data.utr.trim()) {
      errors.utr = "UTR is required.";
      isValid = false;
    } else if (!utrPattern.test(data.utr)) {
      errors.utr = "Invalid UTR number. It should be alphanumeric with 12-22 characters.";
      isValid = false;
    } else {
      errors.utr = "";
    }
  
    if (!data.image) {
      errors.image = "Image is required.";
      isValid = false;
    } else {
      errors.image = "";
    }
  
    setFormErrors(errors);
    return isValid;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // setTouchedFields((prevState) => ({
    //   ...prevState,
    //   [name]: true,
    // }));

    // Validate the form on each input change
    validateForm({ ...formData, [name]: value });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    }
    // setTouchedFields((prevState) => ({
    //   ...prevState,
    //   [name]: true,
    // }));

    validateForm({ ...formData, [name]: files && files[0] });
  };

  const user_Detail = localStorage.getItem("adminDetails");
    let user_id = user_Detail && JSON.parse(user_Detail || '')?.user_id;

    

  const handleSubmit = () => {
    if (validateForm()) {
        getWellet(formData.image, userId, user_id, methodId, formData.amount, formData.utr, "wallet", "1");
    } else {
      console.log("Form is invalid");
    }
  };

  useEffect(() => {
    if (data && userId && domainIds) {
        setMethodId(data[0]?._id);
      getParentPaymentDetails(userId, domainIds, data[0]?._id);
    }
  }, [data]);

  const handleActive = (id: number, method: string) => {
    setActive(id);
    setMethodId(method);
    getParentPaymentDetails(userId, domainIds, method);
    
  };

  return (
    <Modal
      show={addScreen}
      onHide={() => {
        setFormData({ amount: "", utr: "", image: "" });
        setFormErrors({ amount: "", utr: "", image: "" });
        setAddScreen(false);
      }}
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between">
        <div className="d-flex">
          <span>Add Screenshot - {userName}</span>
        </div>
        <Button variant="light btn-sm" onClick={() => {setAddScreen(false);setFormData({ amount: "", utr: "", image: "" });
        setFormErrors({ amount: "", utr: "", image: "" });
        setAddScreen(false);}}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="pt-1">
        <div>
          <ul
            className="arrow-navtabs nav-success bg-light mb-3 nav nav-pills"
            role="tablist"
          >
            {data?.map((item: any, id: number) => {
              return (
                <li
                  className="nav-item"
                  key={item._id}
                  onClick={() => handleActive(id, item._id)}
                >
                  <Button
                    role="tab"
                    data-rr-ui-event-key="arrow-overview"
                    aria-selected="true"
                    className={`nav-link ${active === id ? "active" : ""} btn-light`}
                    tabIndex={0}
                    style={{height: "66px"}}
                  >
                    <span className="d-block">
                      <img src={`${baseURL}/bank_logo/${item?.image}`} alt="" width="50px"/>
                    </span>
                    {/* {item?.category} */}
                  </Button>
                </li>
              );
            })}
          </ul>
          {/* <div>
            {bankDetails?.map((item: any) => {
              if (item?.method_name?.toLowerCase() !== "bank") return null;
              return (
                <div
                  key={item._id}
                  className="border p-3 mb-3 bg-light fw-bold"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">Bank Holder Name:</p>
                    <p className="mb-0">{item?.bank_holder_name}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">Bank Name:</p>
                    <p className="mb-0">{item?.bank_name}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">Account Number:</p>
                    <p className="mb-0">{item?.account_no}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">Ifsc Code: </p>
                    <p className="mb-0">{item?.ifsc_code}</p>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
        <Row className="mb-3">
          {bankDetails?.map((item: any) => {
            if (item?.method_name?.toLowerCase() !== "gpay") return null;
            return (
              <Col xs={6}  key={item._id}>
                <p className="mb-0">
                  <img
                    src={`${baseURL}/${item?.payment_qr}`}
                    alt=""
                    style={{ width: "100%", height: "200px" }}
                  />
                </p>
                <p className="mb-0 text-center fw-bold">{item?.bank_holder_name}</p>
              </Col>
            );
          })}
        </Row>
        <div className="form-floating">
          <Form.Control
            name="amount"
            type="number"
            placeholder="Enter Amount"
            value={formData.amount}
            onChange={handleInputChange}
            isInvalid={!!formErrors.amount}
            onKeyDown={(e) => {
              if (
                e.key === "-" ||
                e.key === "e" ||
                e.key === "+" ||
                e.key === "E" ||
                e.key === "."
              ) {
                e.preventDefault();
              }
            }}
          />
          <Form.Label>Enter Amount</Form.Label>
          {formErrors.amount && (
            <Form.Control.Feedback type="invalid">
              {formErrors.amount}
            </Form.Control.Feedback>
          )}
          <p className="fw-bold">( you can add upto 100000, minimum 100 required. )</p>
        </div>
        <div className="form-floating mt-3">
          <Form.Control
            name="utr"
            type="text"
            placeholder="Enter UTR"
            value={formData.utr}
            onChange={handleInputChange}
            isInvalid={!!formErrors.utr}
          />
          <Form.Label htmlFor="Content">Enter UTR</Form.Label>
          {formErrors.utr && (
            <Form.Control.Feedback type="invalid">
              {formErrors.utr}
            </Form.Control.Feedback>
          )}
        </div>

        <div className="mt-3 mx-auto">
          <div className="mb-1  fs-md text-dark">
            Upload Payment Screenshot :
          </div>
          <div className="input-group">
            <Form.Control
              name="image"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              isInvalid={!!formErrors.image}
            />
            {formErrors.image && (
              <Form.Control.Feedback type="invalid">
                {formErrors.image}
              </Form.Control.Feedback>
            )}
          </div>
        </div>

        <p className="mt-3">
          <b className="fs-6">Note: </b> Our bank accounts or payment details keep changing check the payment details before depositing. If you deposited in other account witch is not currently listed on site then we are not responsible for your loss.
        </p>

        <Button onClick={handleSubmit}>Submit</Button>
      </Modal.Body>
    </Modal>
  );
};

export default AddScreenShot;

import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import DetailsRight from "./DetailsRight";
import DetailsLeft from "./DetailsLeft";
import { useLocation, useParams } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";
import { AlertSelection } from "Common/Modals/AlertSetting";
import Loader from "Common/Loader";

export interface BetLimits {
  market_bookmaker_min_odds_rate: any;
  market_bookmaker_max_odds_rate: any;
  _id: string;
  sport_id: string;
  series_id: string;
  match_id: string;
  market_id: string;
  market_min_stack: number;
  market_max_stack: number;
  market_min_odds_rate: number;
  market_max_odds_rate: number;
  market_back_rate_range: number;
  market_lay_rate_range: number;
  market_max_profit: number;
  market_advance_bet_stake: number;
  market_live_odds_validation: boolean;
  volume_stake_enable: boolean;
  min_volume_limit: number;
  betting_will_start_time: number;
  is_back_bet_allowed: boolean;
  is_lay_bet_allowed: boolean;
  inplay_max_volume_stake_0_10: number;
  inplay_max_volume_stake_10_40: number;
  inplay_max_volume_stake_40: number;
  max_volume_stake_0_10: number;
  max_volume_stake_10_40: number;
  max_volume_stake_40: number;
  inplay_betting_allowed: boolean;
  session_min_stack: number;
  session_max_stack: number;
  session_max_profit: number;
  session_live_odds_validation: boolean;
}

const MatchDetail = () => {
  const [updateFancy, setUpdateFancy] = useState<boolean>(false);
  const [showEnableFancy, setEnableFancy] = useState(true);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [limits, setLimits] = useState<BetLimits>();
  const [limitValidation, setLimitValidation] = useState();
  const [alertData, setAlertData] = useState<AlertSelection>({
    min_amount: null,
    max_amount: null,
    min_bhav: null,
    users: [],
    isAlert: false,
  });

  const handleClose = () => setShow(false);
  const { state } = useLocation();

  const { id } = useParams();
  const getFancyEnable = async () => {
    const { response } = await authServices.getEnabaledFancy({
      enable_fancy: showEnableFancy ? 0 : 1,
      match_id: id || "",
    });
    if (response) {
      if (response.status) {
        snackbarUtil.success(response.msg);
        setShow(false);
        setUpdateFancy(true);
        setEnableFancy(!showEnableFancy);
      } else {
        snackbarUtil.error(response?.msg);
      }
    }
  };

  const handleActiveDeactive = () => {
    getFancyEnable();
  };

  const getLimit = async () => {
    try {
      const { response } = await authServices.getLimits({ match_id: id || "" });

      setLimits(response?.data?.limites);
    } catch (error) {
      console.error("Error fetching limits:", error);
    }
  };
  const getLimitMarket = async (id: string, name: string) => {
    try {
      let payload;
      if (name === "market_id") {
        payload = {
          market_id: id || "",
        };
      } else {
        payload = {
          fancy_id: id || "",
        };
      }
      const { response } = await authServices.getLimits(payload);

      setLimits(response?.data?.limites);
      setLimitValidation(response?.data?.validations);
    } catch (error) {
      console.error("Error fetching limits:", error);
    }
  };
  useEffect(() => {
    getLimit();
    // eslint-disable-next-line
  }, []);
  const userDetails = localStorage.getItem("adminDetails");
  const useridType = userDetails && JSON.parse(userDetails)?.user_type_id;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <React.Fragment>
      <div className="page-content position-relative">
        <Container fluid className="position-relative">
          <Row className="position-relative">
            <Col lg={6}>
              <DetailsLeft
                useridType={useridType}
                limitValidation={limitValidation}
                showEnableFancy={showEnableFancy}
                setEnableFancy={setEnableFancy}
                state={state}
                setShow={setShow}
                setLimits={setLimits}
                getLimit={getLimit}
                limits={limits}
                getLimitMarket={getLimitMarket}
                alertData={alertData}
                setAlertData={setAlertData}
                setIsLoading={setIsLoading}
                screenWidth={screenWidth}
              />
            </Col>
            <Col lg={6} style={{ position: "sticky", top: "0", right: "0" }}>
              <DetailsRight
                useridType={useridType}
                keys="match_id"
                rightv={true}
                alertData={alertData}
              />
            </Col>
          </Row>
        </Container>

        {isLoading && <Loader />}
      </div>
      <Modal  centered={screenWidth < 800}  show={show}  onHide={handleClose}>
        <Modal.Body>
          <h5 className="lg text-center">
            Are you sure you want to {showEnableFancy ? "Disable" : "Enable"}{" "}
            Fancy ?
          </h5>
        </Modal.Body>

        <Modal.Footer className={screenWidth < 800?"justify-content-center":""}>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleActiveDeactive}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default MatchDetail;

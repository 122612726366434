import BreadCrumb from "Common/BreadCrumb";
import { ListPagination } from "Common/ListPagination";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useEffect,  useRef, useState } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Table,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";
import Select from "react-select";
import { debounce } from "lodash";
import Loader from "Common/Loader";

interface ParentLevl {
  user_id: string;
  user_name: string;
  name: string;
  user_type_id: number;
  domain_name: string;
  parent_id: string;
  parent_user_name: string;
  accessToken: string;
  login_status: string;
  is_online: number;
  message: string;
  ip_address: string;
  browser_info: string;
  parent_level_ids: ParentLevelId[];
  expireAt: string;
  login_time: string;
  logout_time: string;
}

interface ParentLevelId {
  user_id: string;
  user_name: string;
  user_type_id: number;
}

interface FilterList {
  value: string;
  label: string;
}

const OnlineUser = () => {
  const [showModal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [aciveLog, setActiveLog] = useState<ParentLevl[]>([]);
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);

  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [usersData, setUsersData] = useState<FilterList[]>([]);
  const [userIPData, setUserIPData] = useState<FilterList[]>([]);
  const [domainList, setDomainList] = useState<FilterList[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputIpValue, setInputIpValue] = useState<string>("");
  const [inputDomainValue, setInputDomainValue] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [selectedIpOptions, setSelectedIpOptions] = useState<any[]>([]);
  const [selectedDomainName, setSelectedDomainName] = useState<any[]>([]);
  const [showList, setList] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const filterRef = useRef<HTMLDivElement>(null);

  const nav = useNavigate();

  const toggleModal = () => {
    setModal(!showModal);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setShowFilter(false);
        setActiveFilter("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getActiveLogs = async (filters: any = {}) => {
    const { response } = await authServices.getActiveLog({
      limit: limit,
      page,
      ...((selectedOptions.length > 0 || selectedIpOptions.length > 0 || selectedDomainName.length > 0) && {
        search: {
          ...(selectedOptions.length > 0 && {
            user_names: selectedOptions.map((item) => item.value),
          }),
          ...(selectedIpOptions.length > 0 && {
            ip_addresses: selectedIpOptions.map((item) => item.value),
          }),
          ...(selectedDomainName.length > 0 && {
            domain_names: selectedDomainName.map((item) => item.value),
          }),
        },
      }),
      ...filters,
    });
    setIsLoading(false);
    setActiveLog(response?.data);
    setListTotal({
      total: response?.metadata
        ? response?.metadata?.total / (filters?.limit || limit)
        : 0,
      page,
    });
  };

  const allLogout = async () => {
    const { response } = await authServices.getAllUserLogout({});

    if (response?.status) {
      setIsLoading(false);
      snackbarUtil.success(response.msg);
      localStorage.removeItem("token");
      localStorage.removeItem("adminDetails");
      localStorage.removeItem("adminRefreshToken");
      localStorage.removeItem("operatorId");
      localStorage.removeItem("userId");
      localStorage.removeItem("isNotification");
      localStorage.removeItem("notifications");
      localStorage.removeItem("columnsDateState");
      nav("/login");
    } else {
      setIsLoading(false);
      snackbarUtil.error(response?.msg);
    }
  };

  const handleLogout = () => {
    setIsLoading(true)
    allLogout();
  };

  const handlePageFilter = (page: number, limit: number) => {
    setIsLoading(true);
    setPage(page);
    setLimit(limit);
  };

  useEffect(() => {
    getActiveLogs({ limit, page });

    const intervalId = setInterval(() => {
      getActiveLogs({ limit, page });
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [page, limit, selectedOptions, selectedIpOptions, selectedDomainName]);

  const getUsresList = async (userName?: string) => {
    const { response } = await authServices.getOnlineUserNames({
      ...(userName && { search: userName }),
    });
    if (response?.data) {
      let userList = response?.data.map((item: string) => ({
        value: item,
        label: item,
      }));
      setUsersData(userList);
    } else {
      setUsersData([]);
    }
  };

  const getUsreIPList = async (ipAddress?: string) => {
    const { response } = await authServices.getOnlineUserIp({
      ...(ipAddress && { search: ipAddress }),
    });
    if (response?.data) {
      let userList = response?.data.map((item: string) => ({
        value: item,
        label: item,
      }));
      setUserIPData(userList);
    } else {
      setUserIPData([]);
    }
  };

  const getAllDomainNames = async (domainName?: string) => {
    const { response } = await authServices.getOnlineDomainName({
      ...(domainName && { search: domainName }),
    });

    if (response?.data) {
      let domainList = response?.data.map((item: string) => ({
        value: item,
        label: item,
      }));
      setDomainList(domainList);
    } else {
      setDomainList([]);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsresList(input);
    } else if (input.length === 0) {
      getUsresList();
    }
  }, 300);

  const fetchIPOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsreIPList(input);
    } else if (input.length === 0) {
      getUsreIPList();
    }
  }, 300);

  const fetchDomainOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getAllDomainNames(input);
    } else if (input.length === 0) {
      getAllDomainNames();
    }
  }, 300);

  useEffect(() => {
    activeFilter === "username"
      ? fetchOptions(inputValue)
      : activeFilter === "dominname"
        ? fetchDomainOptions(inputDomainValue)
        : fetchIPOptions(inputIpValue);

        // eslint-disable-next-line
  }, [inputValue, inputIpValue, inputDomainValue, activeFilter]);

  const toggleFilter = (type: string) => {
    setShowFilter(type === activeFilter ? !showFilter : true);
    setActiveFilter(type === activeFilter ? "" : type);
  };

  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  const getUserAgent = async (userId: string) => {
    const { response } = await authServices.getShowAgent({
      user_id: userId,
    });
    if (response?.data) {
      setAgentList(response?.data?.agents);
    }
  };
  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      // Select all rows
      const allIds = aciveLog.map((item) => item.user_id); // Assuming `user_id` is unique for each row
      setSelectedRows(allIds);
    } else {
      // Deselect all rows
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRows((prev) => [...prev, id]);
    } else {
      setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-2">
          <BreadCrumb title="Online User" pageTitle="Dashboard" back />
          <Button
            variant="dark"
            className="position-absolute top-0 end-0"
            onClick={toggleModal}
          >
            All User Logout
          </Button>
        </div>
        <Card className="pb-3">
          <div className="table-responsive">
            <Table className="table-striped table-nowrap">
              <thead>
                <tr>
                  <th>
                    <div className="form-check form-check-dark">
                      <Form.Check type="checkbox" id="selectAll" checked={selectedRows?.length === aciveLog?.length}
                        onChange={(e) => handleSelectAll(e.target.checked)} />
                    </div>
                  </th>
                  <th>S. No.</th>
                  <th>
                    <div className="d-flex">
                      User Name{" "}
                      <div className="position-relative">
                        <div
                          className="ms-1 cursor-pointer"
                          onClick={() => toggleFilter("username")}
                        >
                          <i
                            className={`ri ri-filter-fill ${showFilter &&
                              activeFilter === "username" &&
                              "text-primary"
                              }`}
                          ></i>
                        </div>
                        {showFilter && activeFilter === "username" && (
                          <div
                            ref={filterRef}
                            className="filterDropDown bg-white position-absolute p-2 w-100 shadow-lg rounded z-1"
                            style={{ minWidth: "200px" }}
                          >
                            <>
                              {
                                <>
                                  <Select
                                    isMulti
                                    isClearable
                                    options={usersData || []}
                                    className="customSelect"
                                    value={selectedOptions}
                                    inputValue={inputValue}
                                    onChange={(selected: any) => {
                                      setIsLoading(true)
                                      setSelectedOptions(selected || []);
                                      setPage(1);
                                    }}
                                    onInputChange={(input: any) =>
                                      setInputValue(
                                        input?.trim()?.toLowerCase()
                                      )
                                    }
                                  />
                                </>
                              }
                            </>
                          </div>
                        )}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="d-flex">
                      Domain Name{" "}
                      <div className="position-relative">
                        <div
                          className="ms-1 cursor-pointer"
                          onClick={() => toggleFilter("dominname")}
                        >
                          <i
                            className={`ri ri-filter-fill ${showFilter &&
                              activeFilter === "dominname" &&
                              "text-primary"
                              }`}
                          ></i>
                        </div>
                        {showFilter && activeFilter === "dominname" && (
                          <div
                            ref={filterRef}
                            className="filterDropDown bg-white position-absolute p-2 w-100 shadow-lg rounded z-1"
                            style={{ minWidth: "200px" }}
                          >
                            <>
                              {
                                <>
                                  <Select
                                    isMulti
                                    isClearable
                                    options={domainList || []}
                                    className="customSelect"
                                    value={selectedDomainName}
                                    inputValue={inputDomainValue}
                                    onChange={(selected: any) => {
                                      setIsLoading(true)
                                      setSelectedDomainName(selected || []);
                                      setPage(1);
                                    }}
                                    onInputChange={(input: any) =>
                                      setInputDomainValue(
                                        input?.trim()?.toLowerCase()
                                      )
                                    }
                                  />
                                </>
                              }
                            </>
                          </div>
                        )}
                      </div>
                    </div>
                  </th>
                  <th>Login Time</th>
                  <th>Status</th>
                  <th>
                    <div className="d-flex">
                      IP Address{" "}
                      <div className="position-relative">
                        <div
                          className="ms-1 cursor-pointer"
                          onClick={() => toggleFilter("ipAddress")}
                        >
                          <i
                            className={`ri ri-filter-fill ${showFilter &&
                              activeFilter === "ipAddress" &&
                              "text-primary"
                              }`}
                          ></i>
                        </div>
                        {showFilter && activeFilter === "ipAddress" && (
                          <div
                            ref={filterRef}
                            className="filterDropDown bg-white position-absolute p-2 w-100 shadow-lg rounded z-1"
                            style={{ minWidth: "200px" }}
                          >
                            <>
                              {
                                <>
                                  <Select
                                    isMulti
                                    isClearable
                                    options={userIPData || []}
                                    className="customSelect"
                                    value={selectedIpOptions}
                                    inputValue={inputIpValue}
                                    onChange={(selected: any) => {
                                      setIsLoading(true)
                                      setSelectedIpOptions(selected || []);
                                      setPage(1);
                                    }}
                                    onInputChange={(input: any) =>
                                      setInputIpValue(
                                        input?.trim()?.toLowerCase()
                                      )
                                    }
                                  />
                                </>
                              }
                            </>
                          </div>
                        )}
                      </div>
                    </div>
                  </th>
                  <th>Browser Info</th>
                </tr>
              </thead>
              <tbody>
                {aciveLog?.map((items, id: number) => {
                  return (
                    <tr key={id}>
                      <td>
                        <div className="form-check form-check-dark">
                          <Form.Check type="checkbox" id="openUser" checked={selectedRows.includes(items?.user_id)}
            onChange={(e) => handleRowSelect(items?.user_id, e.target.checked)}/>
                        </div>
                      </td>
                      <td>{(page - 1) * limit + id + 1}</td>
                      <td
                        className="cursor-pointer"
                        onClick={() => {
                          toggleList(items?.user_name);
                          getUserAgent(items.user_id);
                        }}
                      >
                        {items?.user_name}
                      </td>
                      <td>{items?.domain_name}</td>
                      <td>
                        {moment(items?.login_time).format(
                          "DD-MM-YYYY, hh:mm:ss:SSS A"
                        )}
                      </td>
                      <td
                        className={`${items.login_status?.toLowerCase() === "login_success"
                            ? "text-success"
                            : "text-danger"
                          }`}
                      >
                        {items?.message}
                      </td>
                      <td>{items?.ip_address}</td>
                      <td>{items?.browser_info}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <nav
            aria-label="Page navigation"
            className="px-3 d-flex flex-column flex-sm-row align-items-center"
          >
            <TableEntry
              limit={limit}
              handlelimitFilter={(value: number) =>
                handlePageFilter(page, value)
              }
            />
            <ListPagination
              listTotal={listTotal}
              handlePageFilter={(value: number) =>
                handlePageFilter(value, limit)
              }
            />
          </nav>
        </Card>
     { isLoading &&  <Loader />}
      </Container>

      <Modal
        show={showModal}
        onHide={toggleModal}
        className="zoomIn"
        scrollable
      >
        <Modal.Body className="text-center ">
          <Button
            variant="light btn-sm"
            className="position-absolute"
            style={{ right: "1rem" }}
            onClick={toggleModal}
          >
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
          <i className="bi bi-exclamation-triangle text-warning display-5"></i>
          <h4 className="mt-3">Logout All User</h4>
          <p className="fs-lg mt-4">
            Are you sure you want to Logout all user account?
          </p>
          <div className="mt-4">
            <Button variant="light" className="mx-2" onClick={toggleModal}>
              No
            </Button>
            <Button className="mx-2" onClick={handleLogout}>
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center flex-wrap">
            Parent List of{" "}
            <span className="text-muted fs-xs fw-normal mx-0 mx-sm-2 fs-xs">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {agentList?.length !== 0 ? (
            agentList?.map((item) => {
              return (
                <p>{item.name}({item.user_name})</p>
              );
            })
          ) : (
            <p className="text-center fs-6 p-2 fw-bold">No Data Found!</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OnlineUser;

import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import { useEffect, useState, useMemo, useRef } from "react";
import { authServices } from "Utils/auth/services";
import {
  clearSearchPayload,
  generateOptions,
  updateSearchPayload,
} from "Utils/searchFilter";
import FancyPLTable from "Common/Tables/FancyPLTable";
import { useNavigationType } from "react-router-dom";
import Loader from "Common/Loader";
interface FancyPLProps {
  filter?: boolean;
  activeTab: string;
  isPL: boolean
}

const FancyPL = ({ filter, activeTab, isPL }: FancyPLProps) => {
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [fancyPLData, setFancyPLData] = useState<FancyPLList[]>([]);
  const [searchList, setSearchList] = useState<SportSearchList>();
  const [searchPayload, setSearchPayload] = useState<SportsSearchPayloadData>(
    {}
  );
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigationType = useNavigationType();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    localStorage.setItem("fancyPLStart", startDate.toString());
    localStorage.setItem("fancyPLEnd", endDate.toString());
  }, [startDate, endDate]);

  const eventOptions = useMemo(
    () =>
      generateOptions(
        searchList?.events_m_f || [],
        "event_id",
        "event_name",
        "type"
      ),
    [searchList?.events_m_f]
  );
  const matchOptions = useMemo(
    () => generateOptions(searchList?.matches || [], "match_id", "match_name"),
    [searchList?.matches]
  );
  const seriesOptions = useMemo(
    () => generateOptions(searchList?.series || [], "series_id", "series_name"),
    [searchList?.series]
  );
  const sportsOptions = useMemo(
    () => generateOptions(searchList?.sports || [], "sport_id", "sport_name"),
    [searchList?.sports]
  );

  const getFancyPLList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getFancyPLList({
      limit: limit,
      page: page,
      ...filters,
    });
    setIsLoading(false);
    if (response?.data) {
      setFancyPLData(response?.data[0]?.data);
      setListTotal({
        total: response?.data[0]?.metadata[0] ? response?.data[0]?.metadata[0]?.total / (filters?.limit || limit) : 0,
        page: response?.data[0]?.metadata[0] ? response?.data[0]?.metadata[0]?.page : 0,
      });

      let plList = response?.data[0]?.data;
      var totalStack = 0;
      for (let i = 0; i < plList.length; i++) {
        totalStack = totalStack + plList[i].stack;
      }
      setTotal(totalStack);
    }
  };

  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "eventsProfitLoss",
      ...filters,
    });
    if (response?.data && response?.data.length > 0) {
      setSearchList(response?.data[0].data);
    }
  };

  useEffect(() => {
    let start =
      navigationType === "POP"
        ? localStorage.getItem("fancyPLStart")
        : startDate;
    let end =
      navigationType === "POP" ? localStorage.getItem("fancyPLEnd") : endDate;
    start && setStartDate(new Date(start));
    end && setEndDate(new Date(end));

    if (activeTab === "FancyPL" && isPL) {
      getFancyPLList({
        from_date: new Date(start || ''),
        to_date: new Date(end || ''),
      });
      getSportSearchList({ search: { type: 2 } });
    }
    if (activeTab === "FancyStakeM" && !isPL) {
      getFancyPLList({
        from_date: new Date(start || ''),
        to_date: new Date(end || ''),
      });
      getSportSearchList({ search: { type: 2 } });
    }
  }, [activeTab, isPL]);

  const handlefilterSubmit = () => {
    setPage(1);
    getFancyPLList({
      from_date: startDate,
      to_date: endDate,
      search: searchPayload,
      page: 1,
    });
  };

  const handleSearchFilter = (type: SearchPayloadType, id: string) => {
    const payload: SportsSearchPayloadData = updateSearchPayload(
      type,
      id,
      searchPayload
    );
    payload.type = 2;
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setPage(1);
    getFancyPLList({
      from_date: startDate,
      to_date: endDate,
      search: payload,
      page: 1,
    });
  };

  const handleClearSelection = (type: SearchPayloadType) => {
    const payload: SportsSearchPayloadData = clearSearchPayload(
      type,
      searchPayload
    );
    payload.type = 2;
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setPage(1);
    getFancyPLList({
      from_date: startDate,
      to_date: endDate,
      search: payload,
      page: 1,
    });
  };

  const handleAllClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
    setPage(1);

    getFancyPLList({
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: currentDateTime(),
      search: {},
      page: 1,
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    getFancyPLList({
      from_date: startDate,
      to_date: endDate,
      page: page,
      limit: limit,
      search: searchPayload,
    });
  };



  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i:s",
                      defaultDate: [startDate],
                      maxDate: endDate,
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(new Date(date));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: true,
                      dateFormat: "Y-m-d H:i:s",
                      defaultDate: [endDate],
                      maxDate: currentDateTime(),
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(new Date(date));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Sport Name</Form.Label>
                  <Select
                    isClearable
                    options={sportsOptions}
                    value={
                      sportsOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.sport_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("sport_id", e.value);
                      } else {
                        handleClearSelection("sport_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Series Name</Form.Label>
                  <Select
                    isClearable
                    options={seriesOptions}
                    value={
                      seriesOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.series_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("series_id", e.value);
                      } else {
                        handleClearSelection("series_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Match Name</Form.Label>
                  <Select
                    isClearable
                    options={matchOptions}
                    value={
                      matchOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.match_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("match_id", e.value);
                      } else {
                        handleClearSelection("match_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Market Name</Form.Label>
                  <Select
                    isClearable
                    options={eventOptions}
                    value={
                      eventOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.fancy_id ||
                          option.value === searchPayload?.market_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter(
                          e.type == "1" ? "market_id" : "fancy_id",
                          e.value
                        );
                      } else {
                        handleClearSelection("market_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleAllClearSelection("sport_id")}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <FancyPLTable
            fancyPLData={fancyPLData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            total={total}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default FancyPL;

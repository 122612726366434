
import { Badge, Button, Card, Col, Dropdown, Modal, Table } from "react-bootstrap";
import { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
interface Props {
    fetchData: (days: number, query: string[]) => Promise<void>,
    data: any,
    isLoading: Record<string, boolean>
}


const TopWinner = ({ fetchData, data, isLoading }: Props) => {
    const [showModal, setModal] = useState(false)
    const [listData, setListData] = useState<any>()
    const ToggleModal = (dataList: any) => {
        setModal(!showModal);
        setListData(dataList);
    }
    const [selectName, setSelectName] = useState("Today")
    const handleDate = (days: number, name: string) => {
        setSelectName(name);
        fetchData(days, ["winnersQuery"]);
    }

    const clickHandler = () => {
        setModal(!showModal)
    }

    return (
        <>
            <Col xxl={3} className="position-relative">
                <Card className="card-height-100">
                    <Card.Header className="d-flex border-bottom pb-3">
                        <h5 className="card-title mb-0 flex-grow-1">Top Winner
                            <Badge className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1"
                                onClick={() => ToggleModal(data?.data?.topWinnersList)}>View All</Badge></h5>
                        <div className="flex-shrink-0">
                            <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                                <Dropdown.Toggle as='a' className="text-reset arrow-none mb-0">
                                    <span className="text-muted dropdown-title">{selectName}</span> <i className="mdi mdi-chevron-down ms-1"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-end">
                                    <Dropdown.Item onClick={() => handleDate(1, "Today")}>Today</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(7, "This Week")}>This Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(30, "This Month")}>This Month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Card.Header>
                    <div className="card-body">
                        <div className="table-resposive">
                            <Table>
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-end">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.data?.topWinnersList?.length !== 0 ? data?.data?.topWinnersList?.slice(0, 10).map((items: any) => {
                                            return (
                                                <tr>
                                                    <td>{items?.user_name}</td>
                                                    <td className={`text-end ${items?.user_pl < 0 ? "text-danger" : ""}`}>{items?.user_pl?.toFixed(2)}</td>
                                                </tr>
                                            )
                                        }) : <tr>
                                            <td colSpan={2} className="text-center">No Data Found!</td>
                                        </tr>
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Card>
                {isLoading?.["winnersQuery"] && <div className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ zIndex: '99999', background: 'rgba(255,255,255,.75)' }}>
                    <BeatLoader />
                </div>}
            </Col>

            <Modal show={showModal} onHide={clickHandler} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <span>Top Winner</span>
                    <Button variant="light btn-sm" onClick={clickHandler}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className='fs-md'>
                    <div className="table-resposive">
                        <Table>
                            <thead className="table-light">
                                <tr>
                                    <th>Name</th>
                                    <th className="text-end">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listData?.map((items: any) => {
                                        return (
                                            <tr>
                                                <td>{items?.user_name}</td>
                                                <td className={`text-end ${items?.user_pl < 0 ? "text-danger" : ""}`}>{items?.user_pl?.toFixed(2)}</td>
                                            </tr>
                                        )
                                    })
                                }


                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal></>
    );
};

export default TopWinner;
import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import { useMemo, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { baseURL } from "Utils/baseUrl";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  depositData: DepositList[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
}

const TotalDepositTable = ({
  depositData,
  page,
  limit,
  listTotal,
  handlePageFilter,
}: Props) => {
  // eslint-disable-next-line
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    snackbarUtil.success("Copy Successfull");
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  function formatDateTime(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  const deposit = useMemo(() => {
    if (!depositData || !Array.isArray(depositData)) {
      return [];
    }
    return depositData.map((items, index: number) => ({
      id: items?._id,
      no: (page - 1) * limit + index + 1,
      name: items?.name,
      parentName: items?.parent_user_name,
      username: items?.user_name,
      amount: items?.amount,
      create: formatDateTime(items?.created_at),
      update: formatDateTime(items?.updatedAt),
      origin: items?.domain_name,
      remark: items?.remark,
      mobile: items?.mobile,
      status: items?.status,
      images: items?.images,
      reference_no: items?.reference_no,
    }));
  }, [depositData, page, limit]);


  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },
      {
        header: "Username",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>{cell?.row?.original?.username}</span>
              <CopyToClipboard
                text={cell?.row?.original?.username}
                onCopy={handleCopy}
              >
                <i className="ms-1 align-middle ri-file-copy-line cursor-pointer"></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Parent Name",
        accessorKey: "parentName",
        enableColumnFilter: false,
      },
      {
        header: "Mobile",
        accessorKey: "mobile",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>{cell?.row?.original?.mobile}</span>
              <CopyToClipboard
                text={cell?.row?.original?.mobile}
                onCopy={handleCopy}
              >
                <i className="ms-1 align-middle ri-file-copy-line cursor-pointer"></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
      {
        header: "Origin",
        accessorKey: "origin",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className={`text-${
                cell?.row?.original?.status === "REJECTED"
                  ? "danger"
                  : "secondary"
              }`}
            >
              {cell?.row?.original?.status}
            </span>
          );
        },
      },
      {
        header: "UTR No.",
        accessorKey: "reference_no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <a  href={`${baseURL}/${cell?.row?.original?.images}`} target="_blank" rel="noreferrer">
              {cell?.row?.original?.reference_no}
            </a>
          );
        },
      },
      {
        header: "Remark",
        accessorKey: "remark",
        enableColumnFilter: false,
      },
      {
        header: "Request Time",
        accessorKey: "create",
        enableColumnFilter: false,
      },
      {
        header: "Updated Time",
        accessorKey: "update",
        enableColumnFilter: false,
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={deposit || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <nav aria-label="Page navigation" className="px-3 d-flex">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>
    </>
  );
};

export default TotalDepositTable;

import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const DownloadAppButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const wasDismissed = localStorage.getItem("appInstallPromptDismissed");
    if (wasDismissed !== "true") {
      const handleBeforeInstallPrompt = (event: any) => {
        event.preventDefault();
        setDeferredPrompt(event);
        setShowModal(true);
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }
  }, []);

  const handleDownloadClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the installation prompt");
      } else {
        console.log("User dismissed the installation prompt");
      }
      setDeferredPrompt(null);
    }
    setShowModal(false);
  };

  const handleCancelClick = () => {
    localStorage.setItem("appInstallPromptDismissed", "true");
    setShowModal(false);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCancelClick}
        className="zoomIn"
        backdrop="static"
        keyboard={true}
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div>Install Our App</div>
          <Button variant="light btn-sm" onClick={handleCancelClick}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p>
            You can install our app for a better experience. Would you like to
            install it now?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button onClick={handleDownloadClick}>Install</Button>
          <Button variant="light" onClick={handleCancelClick}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadAppButton;

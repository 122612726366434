import { FC, useEffect,  useState } from 'react';
import { Card,  Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { authServices } from 'Utils/auth/services';

interface Props {
    active: number
}

const CasinoHome: FC<Props> = ({ active }) => {

    const [casinoData, setCasinoData] = useState<casinoRes[]>([]);
    const nav = useNavigate();

    const fetchSportData = async () => {
        try {
            const { response } = await authServices.getSportData({ sport_id: active?.toString() });
            if (response?.data) {
                setCasinoData(response?.data);

            } else {
                console.error('No data received for sports data');
            }
        } catch (error) {
            console.error('Error fetching sports data:', error);
        }
    };


    useEffect(() => {
        fetchSportData();
    }, [active])


    const handleNavigate = (id:string, name:string)=>{
        nav(`/casino/${id}`);
    }

    return (
        <Card className='mt-3'>
            <Card.Header>
                <h5>Casino<span className='text-muted'>({casinoData?.length || 0})</span></h5>
            </Card.Header>
            <Card.Body className='pt-1 px-3'>
                <Row className='g-3'>
                    {
                        casinoData?.map((items) => {
                            return (
                                <Col className='cursor-pointer' xs={6} sm={4} md={2} onClick={()=>handleNavigate(items?.match_id, items?.match_name)}>
                                    <img src={`/casino/${items?.match_id}.jpeg`} alt='' width="100%" style={{aspectRatio: 1 / 1, borderRadius:"10px"}}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CasinoHome;

import BreadCrumb from "Common/BreadCrumb";
import DepositTable from "Common/Tables/DepositTable";
import TotalDepositTable from "Common/Tables/TotalDepositTable";
import { useEffect, useRef, useState } from "react";
import { Container, Card, Button, Row, Col, Form } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { authServices } from "Utils/auth/services";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

type ChildRef = {
  callChildFunction: () => void;
};

const Deposit = () => {
  const userId = localStorage.getItem("userId");
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [total, setTotal] = useState<number>(0);
  const [depositData, setDepositData] = useState<DepositList[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [parentName, setParentName] = useState<string>("");
  const [minAmount, setMinAmount] = useState<string>("");
  const [maxAmount, setMaxAmount] = useState<string>("");
  const [utr, setUtr] = useState<string>("");
  const [type, setType] = useState<string>("");

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const handleParentNameeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setParentName(event.target.value);
  };

  const handleMaxAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxAmount(event.target.value);
  };

  const handleMinAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMinAmount(event.target.value);
  };

  const handleUTRChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUtr(event.target.value);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = event.target.value;
    setType(type);
    setPage(1);

    getAllDeposit({
      ...(minAmount && { lowestAmount: Number(minAmount) }),
      ...(maxAmount && { highestAmount: Number(maxAmount) }),
      fullSearch: {
        statement_type: "DEPOSIT_REQUEST",
        ...(userName && { user_name: userName }),
        ...(parentName && { parent_user_name: parentName }),
        ...(type && { status: type }),
        ...(utr && { reference_no: utr }),
      },
      page: 1,
    });
  };

  const getAllDeposit = async (filters: any = {}) => {
    const { response } = await authServices.getAllDeposit({
      from_date: startDate,
      to_date: endDate,
      user_id: userId,
      fullSearch: {
        statement_type: "DEPOSIT_REQUEST",
        ...(userName && { user_name: userName }),
        ...(parentName && { parent_user_name: parentName }),
        ...(type && { status: type }),
        ...(utr && { reference_no: utr }),
      },
      limit: limit,
      page: page,
      ...filters,
    });
    if (response?.status && response?.data) {
      setDepositData(response?.data);
      setListTotal({
        total: response?.metadata?.total / (filters?.limit || limit) || 0,
        page: response?.metadata?.page || 1,
      });
      setTotal(response?.depositAmount);
    } else {
      setDepositData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
      setTotal(0);
    }
  };

  useEffect(() => {
    getAllDeposit({});
    // eslint-disable-next-line
  }, []);

  const filterSubmit = () => {
    setPage(1);

    getAllDeposit({
      ...(minAmount && { lowestAmount: Number(minAmount) }),
      ...(maxAmount && { highestAmount: Number(maxAmount) }),
      page: 1,
    });
  };

  const clearFilter = () => {
    setUserName("");
    setParentName("");
    setMinAmount("");
    setMaxAmount("");
    setType("");
    setUtr("");
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
    setPage(1);

    getAllDeposit({
      from_date: getStartTimeOfDay(sevenDaysAgo),
      to_date: currentDateTime(),
      fullSearch: {
        statement_type: "DEPOSIT_REQUEST",
      },
      page: 1,
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);

    getAllDeposit({
      ...(minAmount && { lowestAmount: Number(minAmount) }),
      ...(maxAmount && { highestAmount: Number(maxAmount) }),
      page: page,
      limit: limit,
    });
  };

  function formatDateTime(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Deposit", 10, 10);

    const userDataToExport = depositData?.map((user, index) => [
        index + 1,
        user.user_name,
        user.parent_user_name,
        user.mobile,
        user.amount,
        user.domain_name,
        user.status,
        user.created_at = formatDateTime( user.created_at),
        user.updatedAt = formatDateTime( user.updatedAt),
        user.remark
    ]);

    const headers = ["No", "User Name", "Parent Name", "Mobile", "Amount", "Origin", "Status", "Remark", "Request Time", "Updated Time"];

    autoTable(doc, {
        head: [headers],
        body: userDataToExport as any,
    });
    doc.save("deposit.pdf");
};

const downloadXML = () => {

    let xmlContent = `<?xml version="1.0" encoding="UTF-8"?>\n<Deposit>\n`;

    depositData?.forEach((user, id) => {
        xmlContent += `  <Deposit>\n`;
        xmlContent += `    <No>${id + 1}</No>\n`;
        xmlContent += `    <UserName>${user.user_name}</UserName>\n`;
        xmlContent += `    <ParentName>${user.parent_user_name}</ParentName>\n`;
        xmlContent += `    <Mobile>${user.mobile}</Mobile>\n`;
        xmlContent += `    <Amount>${ user.amount}</Amount>\n`;
        xmlContent += `    <Origin>${ user.domain_name}</Origin>\n`;
        xmlContent += `    <Status>${user.status}</Status>\n`;
        xmlContent += `    <Remark>${user.remark}</Remark>\n`;
        xmlContent += `    <RequestTime>${formatDateTime( user.created_at)}</RequestTime>\n`;
        xmlContent += `    <UpdatedTime>${formatDateTime( user.updatedAt)}</UpdatedTime>\n`;
        xmlContent += `  </Deposit>\n`;
    });

    xmlContent += `</Deposit>`;

    const blob = new Blob([xmlContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'deposit.xml';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const childRef = useRef<ChildRef>(null);

const handleClick = () => {
  if (childRef.current) {
    childRef.current.callChildFunction();
  }
};

  return (
    <div className="page-content">
      <Container fluid>
        <div className="position-relative mb-3 d-flex justify-content-between align-items-center">
          <BreadCrumb title="Deposit" pageTitle="Dashboard" back />
          <Button variant="subtle-dark" onClick={()=> handleClick()}>
            <i className="fs-lg align-middle ri-refresh-line"></i> Refresh
          </Button>
        </div>
        <DepositTable ref={childRef} filterSubmit={filterSubmit} getAllDeposit={getAllDeposit}/>

        <h4 className="mt-4">
          Deposit All{" "}
          <span className="text-muted">(Total Deposit : {total})</span>
        </h4>
        <Card className="mt-3">
          <Card.Header className="border-bottom pb-3">
            <Row className="align-items-end g-3">
              <Col lg={3}>
                <Form.Label>Start Date</Form.Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i:s",
                    defaultDate: [startDate],
                    maxDate: endDate,
                  }}
                  value={startDate}
                  onChange={([date]: [Date]) => {
                    setStartDate(new Date(date));
                  }}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>End Date</Form.Label>
                <Flatpickr
                  className="form-control"
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i:s",
                    defaultDate: [endDate],
                    maxDate: currentDateTime(),
                  }}
                  value={endDate}
                  onChange={([date]: [Date]) => {
                    setEndDate(new Date(date));
                  }}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Min Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={minAmount}
                  placeholder="Enter Min Amount"
                  onChange={handleMinAmountChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Max Amount</Form.Label>
                <Form.Control
                  type="number"
                  value={maxAmount}
                  placeholder="Enter Max Amount"
                  onChange={handleMaxAmountChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  value={userName}
                  placeholder="Enter Username"
                  onChange={handleUsernameChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Parent Username</Form.Label>
                <Form.Control
                  type="text"
                  value={parentName}
                  placeholder="Enter Parent Username"
                  onChange={handleParentNameeChange}
                />
              </Col>
              <Col lg={3}>
                <Form.Label>Status</Form.Label>
                <div className="d-flex">
                  <Form.Select
                    className="me-1 w-75"
                    value={type}
                    onChange={handleTypeChange}
                  >
                    <option value="">All</option>
                    <option value="ACCEPTED">Accepted</option>
                    <option value="REJECTED">Rejected</option>
                  </Form.Select>
                  <Form.Control
                    type="text"
                    value={utr}
                    placeholder="Enter UTR No."
                    onChange={handleUTRChange}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <Button className="me-2" onClick={filterSubmit}>
                  Search
                </Button>
                <Button variant="subtle-primary" onClick={clearFilter}>
                  Clear
                </Button>
                <Button variant="success" className="ms-2" onClick={downloadPDF}>
                  <i className="fs-md bi bi-file-earmark-pdf-fill"></i>
                </Button>
                <Button variant="danger" className="ms-2" onClick={downloadXML}>
                  <i className="fs-md bi bi-file-earmark-excel-fill"></i>
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <TotalDepositTable
              depositData={depositData}
              page={page}
              limit={limit}
              listTotal={listTotal}
              handlePageFilter={handlePageFilter}
            />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Deposit;

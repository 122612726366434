import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import ToggleSwitch from "../ToggleSwitch";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface PermissionModalProps {
    show: any;
    userName: string;
    domain_id: string | undefined;
    isBonusAllowed: boolean;
    getWebsiteList: () => Promise<void>;
    setPermission: Dispatch<SetStateAction<boolean>>
}

const AllowBonusModal = ({ setPermission, show, userName, domain_id, isBonusAllowed, getWebsiteList }: PermissionModalProps) => {
    const [permissionData, setPermissionData] = useState<boolean>(false);

    const updateIsB2C = async () => {
        const { response } = await authServices.updateDomainBonus({ domain_id: domain_id || '' });
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            setPermissionData(!permissionData);
            getWebsiteList();
            setPermission(false);
        } else {
            snackbarUtil.error(response?.msg);
        }
    };

    useEffect(() => {
        setPermissionData(isBonusAllowed);
    }, [isBonusAllowed]);

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPermissionData(event.target.checked);
    };

    return (
        <Modal show={show} onHide={() => setPermission(false)} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Bonus Of {userName}</span>
                <Button variant="light btn-sm" onClick={() => setPermission(false)}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Row className="gap-3">
                    <Col sm={5}>
                        <div className="d-flex align-items-center">
                            <ToggleSwitch checked={permissionData} id="MultiLogin" onChange={handleToggle} />
                            <Form.Label className="form-check-label ms-2" htmlFor="MultiLogin">Allow Bonus</Form.Label>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-top">
                <Button variant="light" onClick={() => setPermission(false)}>Cancel</Button>
                <Button variant="success" onClick={() => updateIsB2C()}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AllowBonusModal;

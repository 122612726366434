import { ApiResource } from "../apiService";

export const authResourcs: {
  [x: string]: ApiResource;
} = {
 
  LOGIN: {
    URL: "login/client-login",
    METHOD: "POST",
  },
  CHANGE_PASSWORD: {
    URL: `/user/updateForChangePasswordAfterLogin`,
    METHOD: "POST",
  },
  
  SET_TRANS: {
    URL: "/user/setTransactionPassword",
    METHOD: "POST",
  },
  
  TELEGRAM_CODE: {
    URL: "/telegram/generateTelegramConnectionId",
    METHOD: "POST",
  },
  GET_EXPOSURE_SPORT: {
    URL: "/bet/getExposuresEventWise",
    METHOD: "POST",
  },
  GET_EXPV1_SPORT: {
    URL: `/bet/getExposuresV1`,
    METHOD: "POST",
  },

  GET_CASINO_EXPOSURE: {
    URL: "/casino-exposures",
    METHOD: "POST",
  },
  SHOW_BALANCE: {
    URL: "/user/getUserBalanceV1",
    METHOD: "POST",
  },
  GET_SPORT: {
    URL: "/sports/getSports",
    METHOD: "POST",
  },
  GET_SPORT_DATA: {
    URL: "/match/homeMatchesV2",
    METHOD: "POST",
  },
  GET_NEWS: {
    URL: "/news/getNews",
    METHOD: "POST",
  },
  GET_SERIES: {
    URL: "/series/getSeries",
    METHOD: "POST",
  },
  ENABLED_FANCY: {
    URL: "/match/enableFancy",
    METHOD: "POST",
  },
  UPDATE_MATCH_STATUS: {
    URL: "/match/updateMatchStatus",
    METHOD: "POST",
  },
  UPDATE_EVENT: {
    URL: "/event/update",
    METHOD: "POST",
  },
  VALID_TOKEN: {
    URL: "/user/validateToken",
    METHOD: "GET",
  },
  GET_MATCHDETAILS: {
    URL: "/match/matchDetails",
    METHOD: "POST",
  },
  GET_MATCHDETAILS_RUNNER: {
    URL: "/match/matchDetailsRunners",
    METHOD: "POST",
  },
  FANCY_DATA: {
    URL: "/fancy/getFancies",
    METHOD: "POST",
  },
  FANCY_LIVE_DATA: {
    URL: "/fancy/getFancyLiveData",
    METHOD: "POST",
  },
  BETS: {
    URL: "/bet/bets",
    METHOD: "POST",
  },
  GETONLINE_FANCY: {
    URL: "/fancy/getOnlineApiFancy",
    METHOD: "POST",
  },
  UPDATE_FANCY_STATUS: {
    URL: "/fancy/updateFancyStatus",
    METHOD: "POST",
  },
  UPDATE_FANCY: {
    URL: "/fancy/updateFancy",
    METHOD: "POST",
  },
  GET_TEAM_POSITION: {
    URL: "/bet/getTeamPosition",
    METHOD: "POST",
  },
  GET_LIMIT: {
    URL: "/event/getLimites",
    METHOD: "POST",
  },
  UPDATE_LIMITS: {
    URL: "/event/updateLimites",
    METHOD: "POST",
  },

  ODDS_ABONDONED: {
    URL: "/bet/oddsAbandoned",
    METHOD: "POST",
  },
  UPDATE_MARKET_STATUS: {
    URL: "/market/updateMarketStatus",
    METHOD: "POST",
  },
  GET_MARKET_POSITION: {
    URL: "/market/getMarketAgentUserPositions",
    METHOD: "POST",
  },
  ADD_USER: {
    URL: "/user/detailsForAdd",
    METHOD: "POST",
  },
  USER_REGISTER: {
    URL: "/user/register",
    METHOD: "POST",
  },
  GET_CLOSE_USER: {
    URL: `/user/closedUsersList`,
    METHOD: "POST",
  },
  GET_CLOSE: {
    URL: `/user/closeAccount`,
    METHOD: "POST",
  },
  USER_LIST_WITH_CHILD: {
    URL: `/user/userDetailsWithChildLevelDetails`,
    METHOD: "POST",
  },
  SHOW_PASSWORD: {
    URL: `/user/getRawPasswordOfUser`,
    METHOD: "POST",
  },
  UPDATE_CHILD_PASS: {
    URL: `/user/updateChildPassword`,
    METHOD: "POST",
  },
  CHIPS_OUT: {
    URL: `/account/chipInOut`,
    METHOD: "POST",
  },
  GET_COMMITTION: {
    URL: `/user/getCommission`,
    METHOD: "POST",
  },
  UPDATE_COMMITTION: {
    URL: `/userSettings/update-commission`,
    METHOD: "POST",
  },
  GET_LOGO: {
    URL: `/content/getLogo`,
    METHOD: "POST",
  },
  UPADATE_CR: {
    URL: `/user/updateCreditReference`,
    METHOD: "POST",
  },
  ALLOW_MULTI_LOGIN: {
    URL: `/user/allowAndNotAllowAgentsMultiLogin`,
    METHOD: "POST",
  },
  ALLOW_BETLOCK_LOCK: {
    URL: `/user/updateUserStatusBettingLockUnlock`,
    METHOD: "POST",
  },
  ALLOW_FANCYLOCK_LOCK: {
    URL: `/user/updateUserStatusFancyBetLock`,
    METHOD: "POST",
  },
  ALLOW_FANCYUNLOCK_LOCK: {
    URL: `/user/updateUserStatusFancyBetUnlock`,
    METHOD: "POST",
  },
  USER_ACC_LOCK: {
    URL: `/user/lockAccount`,
    METHOD: "POST",
  },
  CLOSE_ACCOUNT: {
    URL: `/user/closeAccount`,
    METHOD: "POST",
  },
  EVENT_LOCK: {
    URL: `/user/update/eventSettingsCheck`,
    METHOD: "POST",
  },
  GET_PAETNERSHIP_LIST: {
    URL: `/user/getPartnershipListByUserId`,
    METHOD: "POST",
  },
  UPDATE_PARTNERSHIP: {
    URL: `/user/updatePartnershipList`,
    METHOD: "POST",
  },
  HORSE_RACING: {
    URL: `/match/getCountryCodeListOnly`,
    METHOD: "POST",
  },
  MARKETCODE_BY_COUNTRY: {
    URL: `/market/getMarketsByCountryCode`,
    METHOD: "POST",
  },
  GET_EVENT_LIMIT: {
    URL: `/event/getEventsLimit`,
    METHOD: "POST",
  },
  DELETE_BET: {
    URL: `/bet/deleteBet`,
    METHOD: "POST",
  },
  USER_SETTING: {
    URL: `/userSettings/getSportsWise`,
    METHOD: "POST",
  },
  USER_SETTING_UPDATE: {
    URL: `/userSettings/update`,
    METHOD: "POST",
  },
  GET_ALL_WEBSITE: {
    URL: `/website/getAllWebsite`,
    METHOD: "GET",
  },
  UPDATE_STACK: {
    URL: `/user/updateMatchStack`,
    METHOD: "POST",
  },
  SHOW_AGENT: {
    URL: `/user/showAgents`,
    METHOD: "POST",
  },
  GET_ACTIVE_LOG: {
    URL: `/user/getActivityLogs`,
    METHOD: "POST",
  },
  GET_ALL_LOGOUT: {
    URL: `/user/logoutAll`,
    METHOD: "POST",
  },
  GET_SPORT_LIST: {
    URL: `/sport/getSports`,
    METHOD: "POST",
  },
  GET_MATCHES: {
    URL: `/match/getMatches`,
    METHOD: "POST",
  },
  UPDATE_SPORT_STATUS: {
    URL: `/sports/updateSportsStatus`,
    METHOD: "POST",
  },
  GET_MARKET: {
    URL: `/market/getMarkets`,
    METHOD: "POST",
  }, 
  GET_UPDATE_SERIES_STATUS: {
    URL: `/series/updateSeriesStatus`,
    METHOD: "POST",
  }, 
  CREATE_SERIES: {
    URL: `/series/createSeries`,
    METHOD: "POST",
  }, 
  CREATE_MATCH: {
    URL: `/match/createMatch`,
    METHOD: "POST",
  }, 
  GET_TV_SCORE: {
    URL: `/event/getTVandScoreBoardURL`,
    METHOD: "POST",
  }, 
  UPDATE_TV_URL: {
    URL: `/event/updateTVandScoreBoardURL`,
    METHOD: "POST",
  }, 
  AGENT_USER_LIST: {
    URL: `user/getUsersListDiamond`,
    METHOD: "POST",
  }, 
  STATEMENT_LIST: {
    URL: `account/statements`,
    METHOD: "POST",
  }, 
  CHIP_SUMMARY_LIST: {
    URL: `report/settlementReportV2`,
    METHOD: "POST",
  }, 
  CHIP_SUMMARY_HISTORY: {
    URL: `report/settlementCollectionHistoryV2`,
    METHOD: "POST",
  },
  CHIP_SETTLEMENT: {
    URL: `account/makeSettlementV2`,
    METHOD: "POST",
  },
  CASINO_BETS: {
    URL: `lotus/bets`,
    METHOD: "POST",
  },
  PROFIT_LOSS: {
    URL: `report/eventsProfitLoss`,
    METHOD: "POST",
  },
  SPORTS_SEARCH: {
    URL: `event/lists`,
    METHOD: "POST",
  },
  OPEN_BETS: {
    URL: `bet/openBets`,
    METHOD: "POST",
  },
  SETTLED_BETS: {
    URL: `bet/settledBets`,
    METHOD: "POST",
  },
  SPORTS_WISE_PL: {
    URL: `report/sportsWiseUsersPL`,
    METHOD: "POST",
  },
  FANCY_PL: {
    URL: `fancy/fancyStake`,
    METHOD: "POST",
  },
  FANCY_STAKE_USER_WISE: {
    URL: `fancy/fancyStakeUsersWise`,
    METHOD: "POST",
  },
  FANCY_TOTAL_STAKE_USER_WISE: {
    URL: `fancy/fancyTotalStakeUsersWise`,
    METHOD: "POST",
  },
  CREATE_NEWS: {
    URL: "news/create",
    METHOD: "POST",
  },
  DELETE_NEWS: {
    URL: "news/delete",
    METHOD: "POST",
  },
  MY_MARKET: {
    URL: "bet/getExposures",
    METHOD: "POST",
  },
  SOCKET_STATUS: {
    URL: "globalsetting/getSocketStatus",
    METHOD: "GET",
  },
  UPDATE_SOCKET: {
    URL: "globalsetting/updateSocketStatus",
    METHOD: "POST",
  },

  MATCH_STACK: {
    URL: "user/getUserMatchStack",
    METHOD: "POST",
  },
  REDISH_FLUSH: {
    URL: "match/flushCache",
    METHOD: "POST",
  },
  EVENT_BLOCK: {
    URL: "/event/block",
    METHOD: "POST",
  },
  TV_SCORE: {
    URL: "/match/getTvUrlScoreboardUrl",
    METHOD: "POST",
  },
  GET_FANCY_LABILATY: {
    URL: "/bet/getFancyLiability",
    METHOD: "POST",
  },
  GET_FANCIES_LABILATY: {
    URL: "/bet/getFanciesLiability",
    METHOD: "POST",
  },
  GET_RUN_TIME_FANCY: {
    URL: "/fancy/getRunTimeFancyPositionV1",
    METHOD: "POST",
  },
  GET_USER_MATCH_STACK: {
    URL: "/user/getUserMatchStack",
    METHOD: "POST",
  },
  GET_USER_LIST: {
    URL: "/user/getUserByUserName",
    METHOD: "POST",
  },
  GET_FRAUD_BETS: {
    URL: "/bet/fraudBets",
    METHOD: "POST",
  },
  DISABLE_TELEGRAM_OTP: {
    URL: "/user/disableTelegramSentOTP",
    METHOD: "POST",
  },
  DISABLE_TELEGRAM: {
    URL: "/user/disableTelegramVerifyOTP",
    METHOD: "POST",
  },
  RESET_TV: {
    URL: "/match/resetTVandScoreBoardURL",
    METHOD: "POST",
  },
  DEPOSIT_PENDING_REQUEST: {
    URL: "/wallet/getwalletTransactionList",
    METHOD: "POST",
  },
  DEPOSIT_ALL_REQUEST: {
    URL: "/wallet/getwalletAllTransaction",
    METHOD: "POST",
  },
  DEPOSIT_REJECT: {
    URL: "/wallet/depositRejected",
    METHOD: "POST",
  },
  DEPOSIT_ACCEPT: {
    URL: "/wallet/depositAcceptedByDeler",
    METHOD: "POST",
  },
  WITHDRAW_REQUEST: {
    URL: "/wallet/traderwithdrawlistV2",
    METHOD: "POST",
  },
  WITHDRAW_REJECT: {
    URL: "/wallet/withdrawRejectedV2",
    METHOD: "POST",
  },
  WITHDRAW_ACCEPT: {
    URL: "/wallet/withDrawalAcceptedByDeler",
    METHOD: "POST",
  },
  ALL_REQUEST: {
    URL: "/wallet/getAllTransactionsList",
    METHOD: "POST",
  },
  DEPOSIT_METHOD: {
    URL: "/wallet/getBankDetails",
    METHOD: "POST",
  },
  BANKING_METHOD: {
    URL: "/wallet/getbankingMethod",
    METHOD: "POST",
  },
  CREATE_DEPOSIT_METHOD: {
    URL: "/wallet/createBankType",
    METHOD: "POST",
  },
  UPDATE_DEPOSIT_METHOD: {
    URL: "/wallet/updatePayment",
    METHOD: "POST",
  },
  DELETE_EXPIRY_DEPOSIT_METHOD: {
    URL: "/wallet/getExpiryDaysAndMsg",
    METHOD: "POST",
  },
  DELETE_DEPOSIT_METHOD: {
    URL: "/wallet/deleteBankDetail",
    METHOD: "POST",
  },
  EDIT_DEPOSIT_METHOD: {
    URL: "/wallet/editBankType",
    METHOD: "POST",
  },
  DELETE_BETS: {
    URL: `/bet/deleteBets`,
    METHOD: "POST",
  },
  UPDATEFANCYORDER: {
    URL: `/fancy/updateFancyOrder`,
    METHOD: "POST",
  },
  ONLINE_USERNAMES: {
    URL: `/user/getOlnieUserNames`,
    METHOD: "POST",
  },
  ONLINE_USER_IP: {
    URL: `/user/getOlnieUserIpAddress`,
    METHOD: "POST",
  },
  ONLINE_DOMAIN: {
    URL: `/user/getOlnieUserDomainNames`,
    METHOD: "POST",
  },
  GET_LOGOUT: {
    URL: `user/logout`,
    METHOD: "POST",
  },
  UPDATE_B2C: {
    URL: `user/markDealerAsB2c`,
    METHOD: "POST",
  },
  UPDATE_SOCIAL_MEDIA: {
    URL: `user/allowSocialMediaDealer`,
    METHOD: "POST",
  },
  UPDATE_DOMAIN_BONUS: {
    URL: `website/updateBonusAllowed`,
    METHOD: "POST",
  },
  GET_PAYMENT_METHOD: {
    URL: `wallet/getPayementMethod`,
    METHOD: "POST",
  },
  GET_PARENT_PAYMENT: {
    URL: `/wallet/getParentPayementDetails`,
    METHOD: "POST",
  },
  WALLET: {
    URL: `wallet/walletchipIn`,
    METHOD: "POST",
  },
};

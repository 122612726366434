import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import ToggleSwitch from "../ToggleSwitch";
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

interface PermissionModalProps {
    show: any;
    userIds: string;
    userName: string;
    getUserList: () => Promise<void>;
    setPermission: Dispatch<SetStateAction<boolean>>
}

const AllowSocailModal = ({ setPermission, show, userIds, userName, getUserList }: PermissionModalProps) => {
    const [permissionData, setPermissionData] = useState<boolean>(false);

    const updateSocial = async () => {
        const { response } = await authServices.updateSocialMedia({ user_id: userIds, allow_social_media_dealer: permissionData });
        if (response?.status) {
            snackbarUtil.success(response?.msg);
            setPermissionData(!permissionData);
            getUserList();
            setPermission(false);
        } else {
            snackbarUtil.error(response?.msg);
        }
    };

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPermissionData(event.target.checked);
      };

    return (
        <Modal show={show} onHide={() => setPermission(false)} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>Social Media of {userName}</span>
                <Button variant="light btn-sm" onClick={() => setPermission(false)}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className="fs-md">
                <Row className="gap-3">
                    <Col sm={5}>
                        <div className="d-flex align-items-center">
                            <ToggleSwitch checked={permissionData} id="MultiLogin" onChange={handleToggle} />
                            <Form.Label className="form-check-label ms-2" htmlFor="MultiLogin">Allow Social Media</Form.Label>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="border-top">
                <Button variant="light" onClick={() => setPermission(false)}>Cancel</Button>
                <Button variant="success" onClick={() => updateSocial()}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AllowSocailModal;

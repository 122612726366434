import React, { useRef, useState } from "react";
import {  Card, Col, Form, Image } from "react-bootstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import authemail from "assets/images/auth/email.png";
import snackbarUtil from "Utils/snackBarUtil";
import { useNavigate } from "react-router-dom";
import { baseURL } from "Utils/baseUrl";

const Verification = () => {
  const nav = useNavigate();
  const [code, setCode] = useState(Array(6).fill(""));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  let userId = localStorage.getItem("verifyUserId");

  const verifyOtp = async (data: URLSearchParams) => {
    try {
      const response = await fetch(
        `${baseURL}/user/verifyOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json, text/plain, */*",
            Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
          },
          body: data,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (!result.status) {
        snackbarUtil.error(result.msg);
        return null;
      }
      return result;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const password = localStorage.getItem("password");

  const handleCompleteCode = async (completeCode: string) => {
    const params = new URLSearchParams();
    params.append("otp", completeCode.toString());
    params.append("user_id", userId || "");
    params.append("grant_type", "password");
    if(password){params.append("password", password)}

    try {
      const data = await verifyOtp(params);

      if (data) {
        localStorage.setItem("token", data.token.accessToken);
        localStorage.setItem("adminDetails", JSON.stringify(data.data));
        localStorage.setItem("adminRefreshToken", data.token.refreshToken);
        localStorage.setItem("operatorId", data.operatorId);
        localStorage.setItem("userId", data.data.user_id);
        snackbarUtil.success(data.msg);

        nav(data.data.is_change_password ? "/" : "/change-password");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleChange = (value: string, index: number) => {
    const newCode = [...code];
    newCode[index] = value.slice(0, 1);
    setCode(newCode);

    setTimeout(() => {
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }, 100);

    if (newCode.every((val) => val !== "")) {
      const completeCode = newCode.join("");
      handleCompleteCode(completeCode);
    }
  };

  const handleFocus = (index: number) => {
    for (let i = 0; i < index; i++) {
      if (code[i] === "") {
        inputRefs.current[i]?.focus();
        return;
      }
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && code[index] === "") {
      const input = e.target as HTMLInputElement;
      if (index > 0 && input.value === "") {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const resendOtp = async (data: URLSearchParams) => {
    try {
      const response = await fetch(
        `${baseURL}/user/telegramResendOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json, text/plain, */*",
            Authorization: "Basic YXBwbGljYXRpb246c2VjcmV0",
          },
          body: data,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (!result.status) {
        snackbarUtil.error(result.msg);
        return null;
      }
      return result;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const handleResendCode = async () => {
    const params = new URLSearchParams();
    params.append("user_id", userId || "");

    try {
      const data = await resendOtp(params);

      if (data) {
        snackbarUtil.success(data.msg);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  return (
    <ParticlesAuth>
      <React.Fragment>
        <Col xxl="6" className="mx-auto">
          <Card className="mb-0 border-0 shadow-none mb-0">
            <Card.Body className="p-sm-5 m-lg-4">
              <div className="text-center mt-2">
                <h5 className="fs-3xl w-75 mx-auto">
                  Security Code Verification Using Telegram App
                </h5>
                <p className="text-muted mb-4 ">
                  Enter 6-digit code from your telegram bot &nbsp;
                  <span className="cursor-pointer text-success" onClick={handleResendCode}>Resend Code</span>
                </p>
                <div className="pb-4">
                  <Image src={authemail} alt="" className="avatar-md" />
                </div>
              </div>
              <div className="p-2 mt-3">
                <Form action="#">
                  <Form.Group className="d-flex gap-md-3 gap-1">
                    {code.map((value, index) => (
                      <Form.Control
                        key={index}
                        ref={(el: HTMLInputElement | null) =>
                          (inputRefs.current[index] = el)
                        }
                        type="number"
                        className="form-control text-center fs-md-3 fs-5 py-md-3 px-1"
                        maxLength={1}
                        value={value}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onFocus={() => handleFocus(index)}
                        onKeyDown={(e) =>
                          handleKeyDown(
                            e as React.KeyboardEvent<HTMLInputElement>,
                            index
                          )
                        }
                      />
                    ))}
                  </Form.Group>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </React.Fragment>
    </ParticlesAuth>
  );
};

export default Verification;

import React, { ReactNode, useEffect, useState } from "react";

//import actions
import {
  changeLayout,
  changeSidebarTheme,
  changeLayoutMode,
  changeLayoutWidth,
  changeLayoutPosition,
  changeTopbarTheme,
  changeLeftsidebarSizeType,
  changeLeftsidebarViewType,
  changeSidebarImageType,
  changeBodyImageType,
} from "slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
// import TopBar from './TopBar';

import Header from "./Header";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
// import Footer from "./Footer";
import { changeLayoutTheme } from "slices/layouts/thunk";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import { authServices } from "Utils/auth/services";
import { getDomainWithoutSubdomain } from "Utils/datefilter";
import { baseURL } from "Utils/baseUrl";

interface LayoutProps {
  children: ReactNode;
}
const Layout = (props: LayoutProps) => {
  const [logo, setLogo] = useState("");
  const dispatch: any = useDispatch();
  const selectLayoutProperties = createSelector(
    (state: any) => state.Layout,
    (layout) => ({
      layoutType: layout.layoutType,
      layoutThemeType: layout.layoutThemeType,
      leftSidebarType: layout.leftSidebarType,
      layoutModeType: layout.layoutModeType,
      layoutWidthType: layout.layoutWidthType,
      layoutPositionType: layout.layoutPositionType,
      topbarThemeType: layout.topbarThemeType,
      leftsidbarSizeType: layout.leftsidbarSizeType,
      leftSidebarViewType: layout.leftSidebarViewType,
      leftSidebarImageType: layout.leftSidebarImageType,
      bodyImageType: layout.bodyImageType,
    })
  );

  const {
    layoutType,
    layoutThemeType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    bodyImageType,
  } = useSelector(selectLayoutProperties);

  /*
    layout settings
    */
  useEffect(() => {
    if (
      layoutType ||
      layoutThemeType ||
      leftSidebarType ||
      layoutModeType ||
      layoutWidthType ||
      layoutPositionType ||
      topbarThemeType ||
      leftsidbarSizeType ||
      leftSidebarViewType ||
      leftSidebarImageType ||
      bodyImageType
    ) {
      window.dispatchEvent(new Event("resize"));
      dispatch(changeLeftsidebarViewType(leftSidebarViewType));
      dispatch(changeLayoutTheme(layoutThemeType));
      dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
      dispatch(changeSidebarTheme(leftSidebarType));
      dispatch(changeLayoutMode(layoutModeType));
      dispatch(changeLayoutWidth(layoutWidthType));
      dispatch(changeLayoutPosition(layoutPositionType));
      dispatch(changeTopbarTheme(topbarThemeType));
      dispatch(changeLayout(layoutType));
      dispatch(changeSidebarImageType(leftSidebarImageType));
      dispatch(changeBodyImageType(bodyImageType));
    }
    // eslint-disable-next-line
  }, [
    layoutType,
    layoutThemeType,
    leftSidebarType,
    layoutModeType,
    layoutWidthType,
    layoutPositionType,
    topbarThemeType,
    leftsidbarSizeType,
    leftSidebarViewType,
    leftSidebarImageType,
    bodyImageType,
    dispatch,
  ]);

    function processURL(url:string) {
      try {
          const urlObj = new URL(url);
          const hostParts = urlObj.hostname.split('.');
          if (hostParts.length > 2) {
              const newHost = hostParts.slice(1).join('.');
              urlObj.hostname = newHost;
          }
          return `${urlObj.origin}${urlObj.pathname}`;
      } catch (error) {
          console.error("Invalid URL:", error);
          return null;
      }
  }
    
    const rootDomain = processURL(baseURL);

  const getLogoData = async () => {
    const domainName =
      window.location.origin?.includes("localhost")
        ? process.env.REACT_APP_API_BASEURL ||""
        : window.location.origin;
    const domainData = getDomainWithoutSubdomain(domainName);

    const data = {
      key: btoa(domainData),
    };

    const { response } = await authServices.getLogo(data);
    if (response?.status) {
      if (response?.data.hasOwnProperty("self_host")) {
        if (response?.data?.self_host) {
          const logoUrl =`${rootDomain}/${response?.data?.description}`
          setLogo(logoUrl);
        } else {
          setLogo(response?.data?.description);
        }
      } else {
        const logoUrl =`${rootDomain}/${response?.data?.description}`;
        setLogo(logoUrl);
      }
    }
  };

  useEffect(() => {
    getLogoData();
    // eslint-disable-next-line
  }, []);




  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Sidebar layoutType={layoutType} logo={logo} />
        <Header logo={logo} />
        <div className="main-content">{props.children}</div>
      </div>
      <RightSidebar />
    </React.Fragment>
  );
};

export default withRouter(Layout);

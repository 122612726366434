import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Market } from "./DetailsLeft";
import { Button, Card, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import ToggleSwitch from "Common/ToggleSwitch";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";
import Marquee from "react-fast-marquee";
import { BetLimits } from ".";
import { useParams } from "react-router-dom";

interface Props {
  combinedDataOdds: Market[] | undefined;
  toggleBook: any;
  toggleBets: any;
  setFancy: React.Dispatch<SetStateAction<boolean>>;
  fancy: boolean;
  setTableBtn: any;
  toggleMatchSetting: any;
  toggleAbond: (id: string, name: string, marketName: string) => void;
  toggleNews: (market_id: any, name: any, marketName: string) => void;
  getMatchDetails: () => Promise<void>;
  getFancyData: () => Promise<void>;
  setActive: Dispatch<SetStateAction<boolean>>;
  setMarketIds: Dispatch<SetStateAction<string>>;
  previousCombinedDataOdds: any;
  limitData: any;
  teamPosition: any;
  showOdds: boolean;
  useridType: any;
}

const MatchedOdds: FC<Props> = ({
  useridType,
  showOdds,
  teamPosition,
  limitData,
  previousCombinedDataOdds,
  setMarketIds,
  setActive,
  combinedDataOdds,
  getMatchDetails,
  getFancyData,
  toggleBook,
  toggleBets,
  setFancy,
  fancy,
  setTableBtn,
  toggleMatchSetting,
  toggleAbond,
  toggleNews,
}) => {
  const { sportid } = useParams();
  const updateLoclstatus = async (is_lock: boolean, market_id: string) => {
    const { response } = await authServices.updateLimits({
      market_id,
      values: { is_lock },
    });
    if (response?.status) {
      snackbarUtil.success(response.msg);
      if (sportid === "4") {
        getFancyData();
      }
      getMatchDetails();
    } else {
      snackbarUtil.error(response.msg);
    }
  };

  const handleLock = (is_lock: boolean, market_id: string) => {
    updateLoclstatus(is_lock, market_id);
  };

  const handleActiveDeactive = (id: number, market_id: string) => {
    setTableBtn(id);
    setActive(true);
    setMarketIds(market_id);
  };

  function convertToKandL(number: number, defaultValue: string) {
    if (isNaN(number) || number == null) {
      return defaultValue;
    }
    const sizeData = Number(number);
    const absNumber = Math.abs(sizeData);

    if (absNumber >= 10000000) {
      return (sizeData / 10000000).toFixed(1) + "Cr";
    } else if (absNumber >= 100000) {
      return (sizeData / 100000).toFixed(1) + "L";
    } else if (absNumber >= 1000) {
      return (sizeData / 1000).toFixed(1) + "K";
    } else {
      return sizeData.toString();
    }
  }

  function compareOdds(
    prevPrice: number | undefined,
    newPrice: number | undefined
  ) {
    if (!prevPrice || !newPrice) return "";
    return prevPrice < newPrice
      ? "back_blink"
      : prevPrice > newPrice
      ? "lay_blink"
      : "";
  }

  const [openMarketMenu, setOpenMarketMenu] = useState(null);

  const handleOpenMenu = (id: any) => {
    if (openMarketMenu === id) {
      setOpenMarketMenu(null);
    } else {
      setOpenMarketMenu(id);
    }
  };

  const filterRef = useRef<HTMLDivElement>(null);

  // Close filter when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Type assertion to use 'event.target' as an 'HTMLElement'
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node)
      ) {
        setOpenMarketMenu(null);
      }
    };

    // Attach event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {combinedDataOdds?.map((items, id: number) => {
        const prevData =
          previousCombinedDataOdds && previousCombinedDataOdds[id];
        const limit = limitData && limitData[items?.market_id];
        const marketData = teamPosition?.[items.market_id];
        if (showOdds && items?.market_name !== "Match Odds") return null;
        return (
          <Card
            key={id + items?.market_id}
            className="p-2 mb-2 ribbon-box border shadow-none"
          >
            <div className="ribbon ribbon-primary ribbon-shape">
              {items?.market_name}
            </div>
            <div className="d-flex justify-content-end align-items-center position-relative">
              <i
                className="bx bxs-info-circle fs-4 d-block d-xxl-none"
                style={{ color: "rgb(28 25 25 / 50%)" }}
                onClick={() => handleOpenMenu(items?.market_id)}
                
              ></i>
              <div
                className={`${
                  openMarketMenu === items?.market_id ? "" : "d-none"
                } 
            position-absolute top-0 mt-4   pill shadow-lg p-3 mb-5 z-3`}
                style={{ background: "#fff", right: "50px" }}
               
              >
                <Button  
                  className="badge-gradient-primary btn-sm border-0 ms-2"
                  onClick={(event) =>
                    {    event.stopPropagation();toggleBets(
                      "market_id",
                      items?.market_id,
                      items?.market_name
                    )}
                  }
                >
                  Bets
                </Button>
                {useridType === 0 && (
                  <>
                    <Button
                      className="badge-gradient-danger btn-sm border-0 ms-2 "
                      onClick={(event) =>
                        {event.stopPropagation();toggleAbond(
                          items?.market_id,
                          "market_id",
                          items?.market_name
                        )}
                      }
                    >
                      Abond
                    </Button>
                    <Button
                      className="badge-gradient-info btn-sm border-0 ms-2"
                      onClick={(event) =>
                        {event.stopPropagation();toggleNews(items?.market_id, "market_id", "market")}
                      }
                    >
                      News
                    </Button>
                  </>
                )}
              </div>
              <span className="fs-3xs d-xxl-block d-none">
                Min-Max: {convertToKandL(limit?.market_min_stack ?? 0, "0")} -{" "}
                {convertToKandL(limit?.market_max_stack ?? 0, "0")}
              </span>

              <Button
                className="badge-gradient-success btn-sm border-0 ms-2"
                onClick={() => toggleBook(items?.market_id, items?.market_name)}
              >
                Book
              </Button>
              <Button
                className="badge-gradient-primary btn-sm d-xxl-block d-none border-0 ms-2"
                onClick={() =>
                  toggleBets("market_id", items?.market_id, items?.market_name)
                }
              >
                Bets
              </Button>
              {useridType === 0 && (
                <>
                  <Button
                    className="badge-gradient-danger btn-sm border-0 ms-2 d-xxl-block d-none"
                    onClick={() =>
                      toggleAbond(
                        items?.market_id,
                        "market_id",
                        items?.market_name
                      )
                    }
                  >
                    Abond
                  </Button>
                  <Button
                    className="badge-gradient-info btn-sm border-0 ms-2 d-xxl-block d-none"
                    onClick={() =>
                      toggleNews(items?.market_id, "market_id", "market")
                    }
                  >
                    News
                  </Button>
                </>
              )}
            </div>
            <span className="fs-3xs d-xxl-none d-block mt-3">
              Min-Max: {convertToKandL(limit?.market_min_stack ?? 0, "0")} -{" "}
              {convertToKandL(limit?.market_max_stack ?? 0, "0")}
            </span>
            <div className="table-responsive">
              <Table className="table-borderless align-middle table-nowrap mb-0 mt-1">
                <thead>
                  <tr>
                    <th scope="col" className="py-2 px-1">
                      <div className="d-flex align-items-center">
                        <span>P/S</span>
                        <ToggleSwitch
                          On="S"
                          Off="F"
                          id="fancy"
                          className="ms-2"
                          onChange={() => setFancy(!fancy)}
                          checked={fancy ? true : false}
                        />
                        {useridType === 0 && (
                          <>
                            <ToggleSwitch
                              On="On"
                              Off="Off"
                              id="tableBtn"
                              className="ms-2"
                              onChange={() =>
                                handleActiveDeactive(
                                  items?.is_active,
                                  items?.market_id
                                )
                              }
                              checked={items?.is_active ? true : false}
                            />
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  {" "}
                                  Market-Setting{" "}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="btn-ghost btn-sm p-0 ms-2"
                                onClick={() =>
                                  toggleMatchSetting(
                                    items?.market_id,
                                    "market_id",
                                    items?.market_name
                                  )
                                }
                              >
                                <i className="ri-settings-4-line fs-xl"></i>
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">
                                  {" "}
                                  {!items?.is_lock ? "UnLock" : "Lock"}{" "}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="btn-ghost btn-sm p-0 ms-2"
                                onClick={() =>
                                  handleLock(!items?.is_lock, items?.market_id)
                                }
                              >
                                <i
                                  className={`fs-xl ${
                                    !items?.is_lock
                                      ? "ri-lock-unlock-line"
                                      : "ri-lock-line"
                                  }`}
                                ></i>
                              </Button>
                            </OverlayTrigger>
                          </>
                        )}
                      </div>
                    </th>
                    <th className="py-2 px-1">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="px-2 textBack">Back</div>
                        <div className="px-2 textLay">Lay</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items?.runners?.map((match, index: number) => {
                    const prevRunner = prevData?.runners
                      ? prevData.runners[index]
                      : null;
                    const oddsBack = match.ex?.availableToBack || [];
                    const oddsLay = match.ex?.availableToLay || [];
                    const oddsBackPrev = prevRunner?.ex?.availableToBack || [];
                    const oddsLayPrev = prevRunner?.ex?.availableToLay || [];
                    const correspondingData = marketData?.find(
                      (d: any) => d.selection_id === match.selectionId
                    );
                    return (
                      <tr key={index + match?.name}>
                        <td className="w-100 p-1">
                          <div className="d-flex flex-column text-wrap">
                            <strong>{match?.name}</strong>
                            {fancy ? (
                              <span
                                className={`fs-2xs ${
                                  correspondingData?.win_loss < 0
                                    ? "text-danger"
                                    : "text-secondary"
                                }`}
                              >
                                {correspondingData?.win_loss?.toFixed(2)}
                              </span>
                            ) : (
                              <span
                                className={`fs-2xs ${
                                  correspondingData?.win_loss_total_exposure < 0
                                    ? "text-danger"
                                    : "text-secondary"
                                }`}
                              >
                                {correspondingData?.win_loss_total_exposure}
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="p-1">
                          <div className="oddsSection d-flex position-relative">
                            {/* <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary backLight ${compareOdds(oddsBackPrev[2]?.price, oddsBack[2]?.price)} lh-sm rounded text-center d-none d-md-block`}>
                                                                <strong className='fs-xs'>{oddsBack && oddsBack[2]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsBack && oddsBack[2]?.size, "--") || "--"}</p>
                                                            </div>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary backLight ${compareOdds(oddsBackPrev[1]?.price, oddsBack[1]?.price)} lh-sm rounded text-center d-none d-md-block`}>
                                                                <strong className='fs-xs'>{oddsBack && oddsBack[1]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsBack && oddsBack[1]?.size, "--") || "--"}</p>
                                                            </div> */}
                            <div
                              style={{ width: "54px" }}
                              className={`oddBtn p-1 text-secondary back ${compareOdds(
                                oddsBackPrev[0]?.price,
                                oddsBack[0]?.price
                              )} lh-sm rounded text-center`}
                            >
                              <strong className="fs-xs">
                                {(oddsBack && oddsBack[0]?.price) || "--"}
                              </strong>
                              <p className="mb-0 fs-2xs">
                                {convertToKandL(
                                  oddsBack && oddsBack[0]?.size,
                                  "--"
                                ) || "--"}
                              </p>
                            </div>
                            <div
                              style={{ width: "54px" }}
                              className={`oddBtn p-1 text-secondary lay ${compareOdds(
                                oddsLayPrev[0]?.price,
                                oddsLay[0]?.price
                              )}  lh-sm rounded text-center`}
                            >
                              <strong className="fs-xs">
                                {(oddsLay && oddsLay[0]?.price) || "--"}
                              </strong>
                              <p className="mb-0 fs-2xs">
                                {convertToKandL(
                                  oddsLay && oddsLay[0]?.size,
                                  "--"
                                ) || "--"}
                              </p>
                            </div>
                            {/* <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary layLight ${compareOdds(oddsLayPrev[1]?.price, oddsLay[1]?.price)}  lh-sm rounded text-center d-none d-md-block`}>
                                                                <strong className='fs-xs'>{oddsLay && oddsLay[1]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsLay && oddsLay[1]?.size, "--") || "--"}</p>
                                                            </div>
                                                            <div style={{ width: "54px" }} className={`oddBtn p-1 text-secondary layLight ${compareOdds(oddsLayPrev[2]?.price, oddsLay[2]?.price)}  lh-sm rounded text-center d-none d-md-block`}>
                                                                <strong className='fs-xs'>{oddsLay && oddsLay[2]?.price || "--"}</strong>
                                                                <p className='mb-0 fs-2xs'>{convertToKandL(oddsLay && oddsLay[2]?.size, "--") || "--"}</p>
                                                            </div> */}
                            {match?.status?.toLowerCase() !== "active" &&
                              items?.status?.toLowerCase() !== "active" && (
                                <div className="position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-xs text-danger border-danger text-uppercase fw-bold">
                                  {match?.status || items?.status}
                                </div>
                              )}
                            {items?.is_lock && (
                              <div className="position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-xs text-danger border-danger text-uppercase fw-bold">
                                LOCK
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <Marquee speed={45}>{items?.news}</Marquee>
          </Card>
        );
      })}
      {combinedDataOdds?.length === 0 && (
        <div className="text-center fs-5 p-3">Market Data Not Found!</div>
      )}
    </>
  );
};

export default MatchedOdds;

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import { SnackbarProvider } from "notistack";
import { InnerSnackbarUtilsConfigurator } from "Utils/snackBarUtil";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Button, Modal } from "react-bootstrap";
import DownloadAppButton from "Common/Modals/DownloadAppModal";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const store = configureStore({ reducer: rootReducer, devTools: true });
export let setErrorRef: any;
export let errorRef: any;
// const DownloadAppButton = () => {
//   const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     const handleBeforeInstallPrompt = (event: any) => {
//       event.preventDefault();
//       setDeferredPrompt(event);
//       setShowModal(true);
//     };

//     window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

//     return () => {
//       window.removeEventListener(
//         "beforeinstallprompt",
//         handleBeforeInstallPrompt
//       );
//     };
//   }, []);

//   const wasDismissed = localStorage.getItem("appInstallPromptDismissed");
//   useEffect(() => {
//     if (wasDismissed === "false") {
//       setShowModal(false);
//     }
//   }, []);

//   const handleDownloadClick = async () => {
//     if (deferredPrompt) {
//       deferredPrompt.prompt();
//       const { outcome } = await deferredPrompt.userChoice;
//       if (outcome === "accepted") {
//         console.log("User accepted the installation prompt");
//       } else {
//         console.log("User dismissed the installation prompt");
//       }
//       setDeferredPrompt(null);
//       setShowModal(false);
//     }
//   };

//   const handleCancelClick = () => {
//     localStorage.setItem("appInstallPromptDismissed", "false");
//     setShowModal(false);
//   };

//   return (
//     <>
//       <Modal
//         show={showModal}
//         onHide={handleCancelClick}
//         className="zoomIn"
//         backdrop="static"
//         keyboard={true}
//       >
//         <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
//           <div>Install Our App</div>
//           <Button variant="light btn-sm" onClick={handleCancelClick}>
//             <i className="ri-close-line fs-xl align-middle"></i>
//           </Button>
//         </Modal.Header>
//         <Modal.Body className="fs-md">
//           <p>
//             You can install our app for a better experience. Would you like to
//             install it now?
//           </p>
//         </Modal.Body>
//         <Modal.Footer className="border-top">
//           <Button onClick={handleDownloadClick}>Install</Button>
//           <Button variant="light" onClick={handleCancelClick}>
//             Cancel
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

  console.warn = () => {};
  const originalConsoleError = console.error;
  console.error = (message, ...args) => {
    if (!message.includes('Each child in a list should have a unique "key" prop')) {
      // originalConsoleError(message, ...args);
    }
  };

  


root.render(
  <React.Fragment>
    <SnackbarProvider
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <InnerSnackbarUtilsConfigurator />
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
          {/* <DownloadAppButton /> */}
        <DownloadAppButton/>
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  </React.Fragment>
);

// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import BreadCrumb from "Common/BreadCrumb";
import Loader from "Common/Loader";
import AddBanner from "Common/Modals/AddBanner";
import UpdateBanner from "Common/Modals/UpdateBanner";
import BannerSettingTable from "Common/Tables/BannerSettingTable";
import { useEffect, useState } from "react";
import { Container, Button, Card} from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

const BannerSetting = () => {
    const [showBanner, setBanner] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [showUpdateBanner, setUpdateBanner] = useState(false);
    const [bannerData, setBannerData] = useState<bannerPayload[]>([]);
    const [bannerDataUpdate, setBannerDataUpdate] = useState();
    const [showDelete, setDelete] = useState(false);
    const [formData, setFormData] = useState<any>({
        type: null,
        title: "",
        slug: "",
        device: "desktop",
        image: null,
        category: false
    });

    const [errors, setErrors] = useState<any>({
        title: false,
        slug: false,
        device: false,
        image: false,
        content_type: false
    });


    const closeUpdateBanner = () => {
        setUpdateBanner(false)
    }

    const toggleBanner = () => {
        setBanner(!showBanner);
        setFormData({
            type: null,
            title: "",
            slug: "",
            device: "desktop",
            image: null,
            category: false
        });

    };
    const getContent = async () => {
        const { response } = await settingServices.getListBanner();
        setIsLoading(false);
        setBannerData(response?.data);
    };

    const getDeleted = async (slug: string) => {
        const { response } = await settingServices.getDeleted({
            slug
        });
        if (response) {
            if (response?.status) {
                setIsLoading(false);
                snackbarUtil.success(response?.msg);
                getContent();
                setDelete(false)
            } else {
                setIsLoading(false);
                snackbarUtil.error(response?.msg);
            }
        }
    };

    const getUploadContentAgent = async (title: string, slug: string, category: boolean | string, submissionData1: any) => {

        const { response } = await settingServices.getUploadContentAgent(title, slug, category, submissionData1);
        if (response?.status) {
            setIsLoading(false);
            snackbarUtil.success(response?.msg);
            setBanner(false);
            setUpdateBanner(false);
            getContent();
        } else {
            setIsLoading(false);
            snackbarUtil.error(response?.msg);
        }
    };

    useEffect(() => {
        getContent();
    }, []);

    const userDetails = localStorage.getItem("adminDetails");
    const userId = userDetails && JSON.parse(userDetails)?.user_type_id;

    const host = window.location.hostname

    const handleChange = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value
        });

        setErrors({
            ...errors,
            [field]: false
        });


    };



    const validateForm = () => {
        const newErrors = {
            title: !formData?.title?.trim(),
            slug: !formData?.slug?.trim(),
            image: !formData.image,
        };

        setErrors(newErrors);
        return !Object.values(newErrors).includes(true);
    };

    const handleSubmit = async () => {

        if (validateForm()) {
            setIsLoading(false);
            const submissionData = new FormData();
            submissionData.append('content_type', formData.type?.value || "");
            submissionData.append('title', formData.title);
            submissionData.append('slug', formData.slug);
            if (formData.image) {
                submissionData.append('image', formData.image);
            }
            const submissionData1 = new FormData();
            if (formData.image) {
                submissionData1.append('image', formData.image);
            }
            const cat = formData?.category ? true : false;
            

            if(userId === 8){
               const newSlug = formData?.device === "desktop"? `${formData?.slug}-${host}-wl`:`${formData?.slug}-m.${host}-wl`
                getUploadContentAgent(formData?.title, newSlug, "1", submissionData1);
            }else{
                getUploadContentAgent(formData?.title, formData?.slug, cat, submissionData1);
            }
        }
    };




    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mb-2 d-flex justify-content-between align-items-center">
                    <BreadCrumb title="Banner Setting" pageTitle="Dashboard" back />
                    <Button variant="subtle-dark" onClick={toggleBanner}>
                        <i className="fs-lg align-middle ri-add-fill"></i> Add New
                    </Button>
                </div>

                <Card>
                    <Card.Body>
                        <BannerSettingTable setIsLoading={setIsLoading} showDelete={showDelete} setDelete={setDelete} setBannerDataUpdate={setBannerDataUpdate} setUpdateBanner={setUpdateBanner} setFormData={setFormData} getDeleted={getDeleted} bannerData={bannerData} />
                    </Card.Body>
                </Card>
                {isLoading && <Loader />}
            </Container>

            <AddBanner toggleBanner={toggleBanner} showBanner={showBanner} formData={formData}
                handleChange={handleChange}
                errors={errors}
                userId={userId}
                setFormData={setFormData}
                handleSubmit={handleSubmit} />

            <UpdateBanner getUploadContentAgent={getUploadContentAgent} bannerDataUpdate={bannerDataUpdate} userId={userId} showBanner={showUpdateBanner} toggleBanner={closeUpdateBanner} />

            {/* <Modal show={showUpdateBanner} onHide={closeUpdateBanner} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold pb-2">
                    <div>Update Banner - {formData?.title}</div>
                    <Button variant="light btn-sm" onClick={closeUpdateBanner}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className="pt-1">
                    <div className="mt-2">
                        <Form.Label>Image</Form.Label>
                        <Form.Control
                            name="image"
                            type="file"
                            accept="image/*"
                            onChange={(e: any) => {
                                const file = e.target.files[0];
                                if (file) {
                                    const fileType = file.type.split("/")[0];
                                    if (fileType !== "image") {
                                        snackbarUtil.error("Please select a valid image file.");
                                        setFormData({
                                            ...formData,
                                            image: null,
                                        });
                                    } else {
                                        handleChange('image', file);
                                    }
                                }
                            }}
                            isInvalid={errors.image}
                        />
                        {errors.image && (
                            <Form.Control.Feedback type="invalid">
                                Please upload an image.
                            </Form.Control.Feedback>
                        )}
                    </div>


                </Modal.Body>
                <Modal.Footer className="border-top">
                    <Button onClick={handleUpdateBanner}>Update</Button>
                    <Button variant="subtle-dark" onClick={closeUpdateBanner}>Cancel</Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
};

export default BannerSetting;

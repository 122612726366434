import { Badge, Button, Card, Col, Dropdown, Modal, Table } from "react-bootstrap";
import { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

interface Props {
    fetchData: ( days: number, query: string[]) => Promise<void>;
    data: any;
    isLoading: Record<string, boolean>
}

const TopDeposit = ({fetchData,data, isLoading }:Props) => {
    const [showModal, setModal] = useState(false);
    const [listData, setListData] = useState<any>();
    const [nameModals, setNameModals] = useState("");
    const ToggleModal = (dataList: any, name:string) => {
        setModal(!showModal);
        setListData(dataList);
        setNameModals(name)
    }
    const [selectName, setSelectName] = useState("Today");


    const handleDate = (days: number, name: string) => {
        setSelectName(name);
        fetchData(days,["transactionQuery"]);
    };
    const getReportData = (type: string) => {
        return data?.data?.transactionalReport?.find((report:any) => report.statement_type === type)?.data || [];
    };

    const clickHandler = () => {
        setModal(!showModal)
    }

    return (
        <>
            <Col xxl={3} className="position-relative">
                <Card className="card-height-100">
                    <Card.Header className="d-flex border-bottom pb-3">
                        <h5 className="card-title mb-0 flex-grow-1">
                            Top Deposit
                            <Badge
                                className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1"
                                onClick={()=>ToggleModal(getReportData("DEPOSIT_REQUEST"), "Top Deposit")}
                            >
                                View All
                            </Badge>
                        </h5>
                        <div className="flex-shrink-0">
                            <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                                <Dropdown.Toggle as="a" className="text-reset arrow-none mb-0">
                                    <span className="text-muted dropdown-title">{selectName}</span>{" "}
                                    <i className="mdi mdi-chevron-down ms-1"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-end">
                                    <Dropdown.Item onClick={() => handleDate(1, "Today")}>Today</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(7, "This Week")}>This Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(30, "This Month")}>This Month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Card.Header>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table>
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-end">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getReportData("DEPOSIT_REQUEST").length !== 0 ? (
                                        getReportData("DEPOSIT_REQUEST")?.slice(0,10)?.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td className="text-end">{item.total_amount}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                No Data Found!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Card>
                {isLoading?.["transactionQuery"] && <div className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ zIndex: '99999', background: 'rgba(255,255,255,.75)' }}>
                    <BeatLoader />
                </div>}
            </Col>

            <Col xxl={3} className="position-relative">
                <Card className="card-height-100">
                    <Card.Header className="d-flex border-bottom pb-3">
                        <h5 className="card-title mb-0 flex-grow-1">
                            Top Withdraw
                            <Badge
                                className="cursor-pointer bg-body-secondary border border-primary text-primary ms-1"
                                onClick={()=>ToggleModal(getReportData("WITHDRAW_REQUEST"), "Top Withdraw")}
                            >
                                View All
                            </Badge>
                        </h5>
                        <div className="flex-shrink-0">
                            <Dropdown className="card-header-dropdown sortble-dropdown cursor-pointer">
                                <Dropdown.Toggle as="a" className="text-reset arrow-none mb-0">
                                    <span className="text-muted dropdown-title">{selectName}</span>{" "}
                                    <i className="mdi mdi-chevron-down ms-1"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-end">
                                    <Dropdown.Item onClick={() => handleDate(1, "Today")}>Today</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(7, "This Week")}>This Week</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDate(30, "This Month")}>This Month</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Card.Header>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table>
                                <thead className="table-light">
                                    <tr>
                                        <th>Name</th>
                                        <th className="text-end">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getReportData("WITHDRAW_REQUEST").length !== 0 ? (
                                        getReportData("WITHDRAW_REQUEST")?.slice(0,10)?.map((item: any, index: number) => (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td className="text-end">{item.total_amount}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                No Data Found!
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Card>
                {isLoading?.["transactionQuery"] && <div className="position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center" style={{ zIndex: '99999', background: 'rgba(255,255,255,.75)' }}>
                    <BeatLoader />
                </div>}
            </Col>


            <Modal show={showModal} onHide={clickHandler} className="zoomIn" scrollable>
                <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                    <span>{nameModals}</span>
                    <Button variant="light btn-sm" onClick={clickHandler}>
                        <i className="ri-close-line fs-xl align-middle"></i>
                    </Button>
                </Modal.Header>
                <Modal.Body className='fs-md'>
                    <div className="table-resposive">
                        <Table>
                            <thead className="table-light">
                            <tr>
                                        <th>Name</th>
                                        <th>Amount</th>
                                    </tr>
                            </thead>
                            <tbody>
                                {
                                    listData?.map((item: any) => {
                                        return (
                                            <tr>
                                               <td>{item.name}</td>
                                               <td>{item.total_amount}</td>
                                            </tr>
                                        )
                                    })
                                }


                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TopDeposit;

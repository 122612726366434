import AllowBonusModal from "Common/Modals/AllowBonusModal";
import CasinoConversionModal from "Common/Modals/CasinoConversionModal";
import DeleteDomain from "Common/Modals/DeleteDomain";
import DomainSettingModal from "Common/Modals/DomainSettingModal";
import PopupImageModal from "Common/Modals/PopupImageModal";
import SocialMediaModal from "Common/Modals/SocialMediaModal";
import TvPermission from "Common/Modals/TvPermission";
import UpdateDomainModal from "Common/Modals/UpdateDomainModal";
import UploadLogoModal from "Common/Modals/UploadLogoModal";
import ToggleSwitch from "Common/ToggleSwitch";
import { FC, useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Pagination, Row, Table } from "react-bootstrap";
import { settingServices } from "Utils/setting/services";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  getWebsiteList: () => Promise<void>,
  domianData: WebsiteResponse[]
}

const DomainSettingTable = ({ getWebsiteList, domianData }: Props) => {
  // State variables
  const [showDelete, setDelete] = useState(false);
  const [showToggle, setToggle] = useState(false);
  const [showDomainSetting, setDomainSetting] = useState(false);
  const [showPassword, setPassword] = useState(false);
  const [showUploadLogo, setUploadLogo] = useState(false);
  const [showSocialMedia, setSocialMedia] = useState(false);
  const [showTvUrl, setTvUrl] = useState(false);
  const [showUpdateDomain, setUpdateDomain] = useState(false);
  const [showPopupImage, setPopupImage] = useState(false);
  const [showCasinoConversion, setCasinoConversion] = useState(false);
  const [password, setPasswordData] = useState('');
  const [userIds, setUserIds] = useState<string | undefined>('');
  const [nameDomain, setNameDomain] = useState<any>('');
  const [hostName, setHostName] = useState<string>('');
  const [casinoConvertRate, setCasinoConvertRate] = useState<number>();
  const [socialIcon, setSocialIcon] = useState();
  const [domainNameData, setDomainNameData] = useState('');
  const [updatePaylod, setUpdatePload] = useState({
    host_name: "",
    id: "",
    password: "",
    site_title: "",
  });
  const [showBonus, setBonus] = useState(false);
  const [bonusAllowed, setBonusAllowed] = useState(false);

  const [tvPaylod, setTVPayload] = useState({
    domain_id: "",
    password: "",
    is_tv_url_premium: "",
  })


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const userDetails = localStorage.getItem("adminDetails");
  const userId = userDetails && JSON.parse(userDetails)?.user_id;

  const toggleDelete = (id?: string, name?: string) => {
    setUserIds(id);
    setNameDomain(name);
    setDelete(!showDelete);
  };
  const toggleDeleteClose = () => {
    setPasswordData("");
    setDomainNameData("");
    setDelete(!showDelete);
  };

  const toggleDomainSetting = (host_name: string, domain_name: string, id: string) => {
    setDomainSetting(!showDomainSetting);
    setUpdatePload((prev) => ({
      ...prev,
      host_name,
      site_title: domain_name,
      id,
    }));
    setNameDomain(domain_name);
  };

  const toggleUploadLogo = (name?: string) => {
    setNameDomain(name);
    setUploadLogo(true)
  };


  const getSocilIcon = async (slug: string) => {
    const { response } = await settingServices.getSocilIcon(
      { slug }
    )
    if (response && response?.status) {
      const socialData = JSON.parse(response?.data?.description);
      setSocialIcon(socialData)
    }

  }

  const toggleSocialMedia = (name: string) => {
    setNameDomain(name);
    const newData = `${name}-social`;
    getSocilIcon(newData)
    setSocialMedia(!showSocialMedia);
  };

  const toggleTvUrl = (id: number, domain_id: string, name: string) => {
    setTvUrl(!showTvUrl);
    setNameDomain(name);
    setTVPayload((prev) => (
      {
        ...prev,
        is_tv_url_premium: id?.toString(),
        domain_id,

      }
    ))
  };
  const getContent = async (popup: string, name: string) => {
    const { response } = await settingServices.getContent(popup, name);
  }

  const toggleUpdateDomain = (name: string) => {
    setNameDomain(name);
    setUpdateDomain(!showUpdateDomain);
  };




  const togglePopupImage = (name: string, host: string) => {
    setNameDomain(name);
    setHostName(host)
    getContent("Popup", name);
    setPopupImage(!showPopupImage);
  };

  const closetogglePopupImage = () => {
    setPopupImage(false)
  }

  const toggleCasinoConversion = (rate: number, id: string, name:string) => {
    setHostName(id);
    setNameDomain(name);
    setCasinoConvertRate(rate);
    setCasinoConversion(!showCasinoConversion);
  };

  const closetoggleCasinoConversion = () => {
    setCasinoConversion(false)
  }

  // const handleDomain = (e:any)=>{
  //   if(e.target.value !== nameDomain){

  //   }

  // }
  const toggleBonus = (name: string, host: string, isAllowed: boolean, id: string) => {
    setNameDomain(name);
    setHostName(host)
    setBonusAllowed(isAllowed);
    setUserIds(id);
    setBonus(true);
  };



  const updateWebsiteSetting = async (id: string) => {
    const { response } = await settingServices.updateWebsiteSetting(id, {
      host_name: updatePaylod?.host_name,
      id: updatePaylod?.id,
      password: updatePaylod?.password,
      site_title: updatePaylod?.site_title,
      userId: userId,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
      setDomainSetting(false);
      setUpdatePload({
        host_name: "",
        id: "",
        password: "",
        site_title: ""
      });
    } else {
      snackbarUtil.error(response?.msg);
    }
  };



  const getAloowUNmatedBet = async (domain_id: string) => {
    const { response } = await settingServices.getAloowUNmatedBet({
      domain_id,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const updateLogo = async (title: string, slug: string, logo: any) => {
    const { response } = await settingServices.updateLogo(title, slug, logo);
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
      setUploadLogo(false);
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  useEffect(() => {
    getWebsiteList();
  }, []);

  const toggleSwitch = (id: string) => {
    getAloowUNmatedBet(id);
  };

 

  const indexOfLastDomain = currentPage * itemsPerPage;
  const indexOfFirstDomain = indexOfLastDomain - itemsPerPage;
  const currentDomains = domianData?.slice(indexOfFirstDomain, indexOfLastDomain);


  const totalPages = Math.ceil(domianData?.length / itemsPerPage);

  const getCreateSocilaMedia = async (data: any) => {
    const { response } = await settingServices.createSocialMediaIcon(data);
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
    } else {
      snackbarUtil.error(response?.msg);
    }
  }
  const getUpdateTV = async (data: any) => {
    const { response } = await settingServices.getUpdateTV(data);
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
      setTvUrl(false)
    } else {
      snackbarUtil.error(response?.msg);
    }
  }
  const updateDomainNewOld = async (old_domain: string, new_domain: string) => {
    const { response } = await settingServices.updateDomainNewOld({
      old_domain,
      new_domain
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
      setUpdateDomain(false)
    } else {
      snackbarUtil.error(response?.msg);
    }
  }
  const updateUpUp = async (data: any) => {
    const { response } = await settingServices.updateUpUp(data);
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
      setPopupImage(false)
    } else {
      snackbarUtil.error(response?.msg);
    }
  }
  const updateCasinoRate = async (casino_conversion_rate: number, domain_id: string) => {
    const { response } = await settingServices.updateCasinoRate({
      casino_conversion_rate,
      domain_id
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      getWebsiteList();
      setCasinoConversion(false)
    } else {
      snackbarUtil.error(response?.msg);
    }
  }




  const handleCloseTv = () => {
    setTVPayload({
      domain_id: "",
      is_tv_url_premium: "",
      password: ""
    })
    setTvUrl(false)
  }



  return (
    <>
      <div className="table-responsive" style={{ minHeight: '390px' }}>
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>Domain</th>
              <th>Site Title</th>
              <th>Unmatch Bet</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              currentDomains?.map((domain, id) => {
                return (
                  <tr key={domain?._id}>
                    <td>{domain?.host_name}</td>
                    <td>{domain?.domain_name}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="me-2">Unmatch Bet Allowed</span>
                        <ToggleSwitch On="Yes" Off="No" id={domain?._id} onChange={() => toggleSwitch(domain?._id)} checked={domain?.unmatch_bet_allowed} />
                      </div>
                    </td>
                    <td>
                      <Dropdown drop="start">
                        <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide btn-sm">
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => toggleDomainSetting(domain?.host_name, domain?.domain_name, domain?._id)}>Update Domain Setting</Dropdown.Item>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => toggleDelete(domain?._id, domain?.domain_name)}>Delete Domain</Dropdown.Item>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => toggleUploadLogo(domain?.domain_name)}>Upload logo</Dropdown.Item>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => toggleSocialMedia(domain?.domain_name)}>Add Social Media</Dropdown.Item>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => toggleTvUrl(domain?.is_tv_url_premium, domain?._id, domain?.domain_name)}>Upload TV Url</Dropdown.Item>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => toggleUpdateDomain(domain?.domain_name)}>Update Domain</Dropdown.Item>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => togglePopupImage(domain?.domain_name, domain?.host_name)}>Update Popup Image</Dropdown.Item>
                          <Dropdown.Item className="py-2" onClick={() => toggleCasinoConversion(domain?.casino_conversion_rate, domain?._id, domain?.domain_name)}>Update Casino Conversion Rate</Dropdown.Item>
                          <Dropdown.Item className="border-bottom py-2" onClick={() => toggleBonus(domain?.domain_name, domain?.host_name, domain?.bonus_allowed, domain?._id)}>Update Bonus Alowed</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>

        <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
          <Pagination className="ms-auto mb-0">
            <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
            {[...Array(totalPages)].map((_, index) => (
              <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => setCurrentPage(index + 1)}>
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
          </Pagination>
        </nav>
      </div>

      <DomainSettingModal nameDomain={nameDomain} updateWebsiteSetting={updateWebsiteSetting} updatePaylod={updatePaylod} setUpdatePload={setUpdatePload} show={showDomainSetting} clickHandler={toggleDomainSetting} />
      <DeleteDomain setPassword={setPassword} showPassword={showPassword} domainNameData={domainNameData} userIds={userIds} nameDomain={nameDomain} setPasswordData={setPasswordData} setDelete={setDelete} setDomainNameData={setDomainNameData} getWebsiteList={getWebsiteList} userId={userId} show={showDelete} clickHandler={toggleDeleteClose} password={password}/>
      <UploadLogoModal setUploadLogo={setUploadLogo} nameDomain={nameDomain} updateLogo={updateLogo} show={showUploadLogo} clickHandler={toggleUploadLogo} />
      <SocialMediaModal getCreateSocilaMedia={getCreateSocilaMedia} socialIcon={socialIcon} domainName={nameDomain} show={showSocialMedia} clickHandler={setSocialMedia} />
      <TvPermission nameDomain={nameDomain} getUpdateTV={getUpdateTV} handleCloseTv={handleCloseTv} setTVPayload={setTVPayload} showPassword={showPassword} setPassword={setPassword} tvPaylod={tvPaylod} showTvUrl={showTvUrl} />

      <UpdateDomainModal updateDomainNewOld={updateDomainNewOld} setUpdateDomain={setUpdateDomain} setNameDomain={setNameDomain} nameDomain={nameDomain} show={showUpdateDomain} clickHandler={toggleUpdateDomain} />

      <PopupImageModal updateUpUp={updateUpUp} hostName={hostName} setHostName={setHostName} nameDomain={nameDomain} setNameDomian={setNameDomain} show={showPopupImage} clickHandler={closetogglePopupImage} />

      <CasinoConversionModal DomainName={nameDomain} updateCasinoRate={updateCasinoRate} nameDomian={hostName} casinoConvetRate={casinoConvertRate} show={showCasinoConversion} clickHandler={closetoggleCasinoConversion} />
      <AllowBonusModal isBonusAllowed={bonusAllowed} userName={nameDomain} domain_id={userIds} show={showBonus} setPermission={setBonus} getWebsiteList={getWebsiteList} />
    </>
  );
};

export default DomainSettingTable;

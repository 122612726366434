import BreadCrumb from "Common/BreadCrumb";
import UserListTable from "Common/Tables/UserListTable";
import UserListTableAgent from "Common/Tables/UserListTableAgent";
import { FC, useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Form,
  Dropdown,
  Modal,
  Table,
} from "react-bootstrap";
import { useNavigate, useNavigationType } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { settingServices } from "Utils/setting/services";
import moment from "moment";
import Loader from "Common/Loader";

interface Props {
  agentStatus: boolean;
}

const getColumnsFromStorage = () => {
  const storedData = localStorage.getItem("columnsDateState");
  return storedData ? JSON.parse(storedData) : null;
};
const getColumnsFromStorageAgent = () => {
  const storedData = localStorage.getItem("columnsDateStateAgent");
  return storedData ? JSON.parse(storedData) : null;
};

const UserList: FC<Props> = ({ agentStatus }) => {
  const user_Detail = localStorage.getItem("adminDetails");
  let user_type_id = JSON.parse(user_Detail || "")?.user_type_id;
  let agent = user_type_id === 0 ? agentStatus : false;

  const navigate = useNavigate();
  const [userData, setUserData] = useState<ChildLevelDetail[]>();
  const [userDataBreadCrumb, setUserDataBreadCrumb] = useState<any>();
  const [userDataAgent, setUserDataAgent] = useState<userListAgentRes[]>([]);
  const [userId, setUserId] = useState<string>("");
  const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
  const [domainId, setDomainId] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [levelId, setLevelId] = useState<any>(null);
  const user_Id = localStorage.getItem("userId");
  const [totalResult, setTotalResult] = useState<any>();
  const [openCRHistory, setOpenCRHistory] = useState<boolean>(false);
  const [CRHistoryData, setCRHistoryData] = useState<creditHistoryRes[]>([]);
  const [totalData, setTotalData] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [formValuesFilter, setFormValuesFilter] = useState<any>({});
  const [showColumn, setShowColumn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [columnsDateState, setColumnsDateState] = useState(() => {
    return (
      getColumnsFromStorage() || [
        { name: "No.", show: true },
        { name: "User Name", show: true },
        { name: "Domain Name", show: true },
        { name: "Level", show: true },
        { name: "C R", show: true },
        { name: "Exposure", show: true },
        { name: "Partnership", show: true },
        { name: "P|L", show: true },
        { name: "Balance", show: true },
        { name: "Credit", show: true },
        { name: "Add Screenshot", show: true },
        { name: "User S", show: true },
        { name: "Bet S", show: true },
        { name: "Action", show: true },
       
      ]
    );
  });

  const [columnsDateStateAgent, setColumnsDateStateAgent] = useState(() => {
    return (
      getColumnsFromStorageAgent() || [
        { name: "No.", show: true },
        { name: "User Name", show: true },
        { name: "Domain Name", show: true },
        { name: "Mobile", show: true },
        { name: "C R", show: true },
        { name: "Exposure", show: true },
        { name: "Client P|L", show: true },
        { name: "Client P|L %", show: true },
        { name: "Partnership", show: true },
        { name: "Pts", show: true },
        { name: "Avail. Balance", show: true },
        { name: "Credit", show: true },
        { name: "User S", show: true },
        { name: "Bet S", show: true },
        { name: "A/C Type", show: true },
        { name: "Action", show: true },
      ]
    );
  });
  const { searchQuery } = formValuesFilter;
  const [limit, setLimit] = useState<number>(50);

  const navigationType = useNavigationType();

  useEffect(() => {
    if (user_Id) setUserId(user_Id);
  }, [user_Id]);

  const getUserList = async (filters: any = {}, pageNo?: number) => {
    if (!userId) return;
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== "")
    );
    let page_no = pageNo ? pageNo : currentPage;
    const { response } = await authServices.getUserListData(userId, {
      limit,
      page: page_no,
      ...cleanedFilters,
    });

    if (response) {
      setIsLoading(false);
    }

    setUserData(response?.data?.childLevelDetails);
    setUserDataBreadCrumb(response?.data);

    const totalPages = Math.ceil(response?.total / limit);
    setTotalResult(totalPages);
    setTotalData(response?.total);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("All User List", 10, 10);

    const userDataToExport = userData?.map((user, index) => [
      index + 1,
      user.user_name,
      user.domain?.site_title,
      user.credit_reference,
      user.liability,
      user.profit_loss,
      user.balance,
      user.point,
      user.user_type_id,
    ]);

    const headers = [
      "No",
      "User Name",
      "Mobile",
      "CR",
      "Exposure",
      "Client P/L",
      "Client P/L %",
      "Point",
      "Avail. Balance",
    ];

    autoTable(doc, {
      head: [headers],
      body: userDataToExport as any,
    });
    doc.save("user_data.pdf");
  };
  const downloadPDFAgent = () => {
    const doc = new jsPDF();
    doc.text("Agent List", 15, 15);

    const userDataToExport = userDataAgent?.map((user, index) => [
      index + 1,
      user.user_name,
      user.mobile,
      user.credit_reference,
      user.exposure,
      user.client_pl,
      user.client_pl_share,
      user.pts,
      user.available_pts,
    ]);

    const headers = [
      "No",
      "User Name",
      "Domain Name",
      "CR",
      "Exposure",
      "P/L",
      "Balance",
      "Point",
      "C|L",
    ];

    autoTable(doc, {
      head: [headers],
      body: userDataToExport as any,
    });
    doc.save("user_data_agent.pdf");
  };

  const downloadXML = () => {
    let xmlContent = `<?xml version="1.0" encoding="UTF-8"?>\n<Users>\n`;

    userData?.forEach((user, id) => {
      xmlContent += `  <User>\n`;
      xmlContent += `    <No>${id + 1}</No>\n`;
      xmlContent += `    <UserName>${user.user_name}</UserName>\n`;
      xmlContent += `    <DomainName>${user.domain?.site_title}</DomainName>\n`;
      xmlContent += `    <CreditReference>${user.credit_reference}</CreditReference>\n`;
      xmlContent += `    <Exposure>${user.liability}</Exposure>\n`;
      xmlContent += `    <ProfitLoss>${user.profit_loss}</ProfitLoss>\n`;
      xmlContent += `    <Balance>${user.balance}</Balance>\n`;
      xmlContent += `    <Point>${user.point}</Point>\n`;
      xmlContent += `    <ChildLevel>${user.user_type_id}</ChildLevel>\n`;
      xmlContent += `  </User>\n`;
    });

    xmlContent += `</Users>`;

    const blob = new Blob([xmlContent], { type: "application/xml" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "user_data.xls";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadXMLAgent = () => {
    let xmlContent = `<?xml version="1.0" encoding="UTF-8"?>\n<Users>\n`;

    userDataAgent.forEach((user, id) => {
      xmlContent += `  <User>\n`;
      xmlContent += `    <No>${id + 1}</No>\n`;
      xmlContent += `    <UserName>${user.user_name}</UserName>\n`;
      xmlContent += `    <DomainName>${user.mobile}</DomainName>\n`;
      xmlContent += `    <CreditReference>${user.credit_reference}</CreditReference>\n`;
      xmlContent += `    <Exposure>${user.credit_reference}</Exposure>\n`;
      xmlContent += `    <ProfitLoss>${user.exposure}</ProfitLoss>\n`;
      xmlContent += `    <Balance>${user.client_pl}</Balance>\n`;
      xmlContent += `    <Point>${user.client_pl_share}</Point>\n`;
      xmlContent += `    <ChildLevel>${user.pts}</ChildLevel>\n`;
      xmlContent += `    <ChildLevel>${user.available_pts}</ChildLevel>\n`;
      xmlContent += `  </User>\n`;
    });

    xmlContent += `</Users>`;

    const blob = new Blob([xmlContent], { type: "application/xml" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "user_data_agent.xls";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadPdf = () => {
    if (agent) {
      downloadPDFAgent();
    } else {
      downloadPDF();
    }
  };
  const handleDownloadXml = () => {
    if (agent) {
      downloadXMLAgent();
    } else {
      downloadXML();
    }
  };

  const getUserListAgent = async (filters: any = {}, pageNo?: number) => {
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== "")
    );
    let page_no = pageNo ? pageNo : currentPage;
    const payload: any = {
      page: page_no,
      limit: limit,
      user_id: userId,
    };

    if (Object.keys(cleanedFilters).length > 0) {
      payload.search = {
        ...cleanedFilters,
      };

      if (payload.search.searchQuery) {
        payload.search.user_name = payload.search.searchQuery
          ?.trim()
          ?.toLowerCase();
        delete payload.search.searchQuery;
      }

      if (payload.search.domainId) {
        payload.search.domain = payload.search.domainId;
        delete payload.search.domainId;
      }

      if (payload.search.levelId) {
        payload.search.title = payload.search.levelId;
        delete payload.search.levelId;
      }
    }
    const { response } = await authServices.getAgentUserList(payload);
    if (response) {
      setIsLoading(false);
    }
    setUserDataAgent(response?.data?.data);
    setUserDataBreadCrumb(response);
    const totalPages = Math.ceil(response?.data?.metadata?.total / limit);
    setTotalResult(totalPages);
    setTotalData(response?.data?.metadata?.total);
  };

  const getAllWebsite = async () => {
    const { response } = await authServices.getAllWebsite();
    const options = response?.data.map((user: any) => ({
      value: user?._id,
      label: user.host_name,
    }));
    setDomainList(options);
  };
  useEffect(() => {
    if (userId?.length !== 0) {
      if (agent) {
        getUserListAgent({
          ...(searchQuery && {
            searchQuery: searchQuery?.trim()?.toLowerCase(),
          }),
          ...(domainId && { domainId }),
          ...(levelId && { levelId }),
        });
      } else {
        getUserList({
          ...(searchQuery && {
            searchQuery: searchQuery?.trim()?.toLowerCase(),
          }),
          ...(domainId && { domainId }),
          ...(levelId && { levelId }),
        });
      }
    }
    // eslint-disable-next-line
  }, [userId, agent, limit, currentPage]);

  useEffect(() => {
    getAllWebsite();
  }, []);

  const handleDomain = (selectedOption: any) => {
    setIsLoading(true);
    const selectedValue = selectedOption ? selectedOption.value : "";
    setDomainId(selectedValue);
    setCurrentPage(1);
    if (agent) {
      getUserListAgent(
        {
          ...(searchQuery && {
            searchQuery: searchQuery?.trim()?.toLowerCase(),
          }),
          domainId: selectedValue,
          ...(levelId && { levelId }),
        },
        1
      );
    } else {
      getUserList(
        {
          ...(searchQuery && {
            searchQuery: searchQuery?.trim()?.toLowerCase(),
          }),
          domainId: selectedValue,
          ...(levelId && { levelId }),
        },
        1
      );
    }
  };
  const handleLevel = (selectedOption: any) => {
    setIsLoading(true);
    const selectedValue = selectedOption ? selectedOption.value : "";
    const selectedLabel = selectedOption ? selectedOption.label : "";
    if (agent) {
      setLevelId(selectedOption ? selectedOption.label : null);
    } else {
      setLevelId(selectedOption ? selectedOption.value : null);
    }
    setCurrentPage(1);
    if (agent) {
      getUserListAgent(
        {
          ...(searchQuery && {
            searchQuery: searchQuery?.trim()?.toLowerCase(),
          }),
          ...(domainId && { domainId }),
          title: selectedLabel,
        },
        1
      );
    } else {
      getUserList(
        {
          ...(searchQuery && {
            searchQuery: searchQuery?.trim()?.toLowerCase(),
          }),
          ...(domainId && { domainId }),
          levelId: selectedValue,
        },
        1
      );
    }
  };
  useEffect(() => {
    if (searchQuery?.length >= 3 || searchQuery?.length === 0) {
      setCurrentPage(1);
      if (agent) {
        getUserListAgent(
          {
            ...(searchQuery && {
              searchQuery: searchQuery?.trim()?.toLowerCase(),
            }),
            ...(domainId && { domainId }),
            ...(levelId && { levelId }),
          },
          1
        );
      } else {
        getUserList(
          {
            ...(searchQuery && {
              searchQuery: searchQuery?.trim()?.toLowerCase(),
            }),
            ...(domainId && { domainId }),
            ...(levelId && { levelId }),
          },
          1
        );
      }
    }
    // eslint-disable-next-line
  }, [searchQuery]);
  const pathname = window.location.pathname;

  useEffect(() => {
    setCurrentPage(1);
    // localStorage.removeItem("report_client");
    // if(pathname === "")
    // setFormValuesFilter({
    //     searchQuery:""
    // })
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  const nav = useNavigate();

  const handleNavigate = (user_id: string, user_name: string) => {
    nav(`/downline-report/${user_id}/${user_name}`);
  };

  useEffect(() => {
    let user =
      navigationType === "POP"
        ? localStorage.getItem("report_client")
        : searchQuery?.trim()?.toLowerCase();
    if (navigationType === "POP") {
      setFormValuesFilter({
        searchQuery: user?.toLowerCase(),
      });
    } else {
      localStorage.removeItem("report_client");
      setFormValuesFilter({
        searchQuery: "",
      });
    }
    // eslint-disable-next-line
  }, [navigationType]);

  const getCreditReferenceLogs = async (user_id: string) => {
    const { response } = await settingServices.getCreditReferenceLogs({
      user_id,
    });
    if (response) {
      setCRHistoryData(response?.data);
    }
  };
  const handleShowCRHistory = (name: string, user_id: string) => {
    setOpenCRHistory(true);
    getCreditReferenceLogs(user_id);
    setUserName(name);
  };

  useEffect(() => {
    localStorage.setItem("columnsDateState", JSON.stringify(columnsDateState));
  }, [columnsDateState]);

  const toggleColumnVisibility = (name: string) => {
    const updatedColumns = columnsDateState.map((col: any) =>
      col.name === name ? { ...col, show: !col.show } : col
    );
    const updatedColumnsAgent = columnsDateStateAgent.map((col: any) =>
      col.name === name ? { ...col, show: !col.show } : col
    );
    localStorage.setItem("columnsDateState", JSON.stringify(updatedColumns));
    localStorage.setItem(
      "columnsDateStateAgent",
      JSON.stringify(updatedColumnsAgent)
    );
    setColumnsDateState(updatedColumns);
    setColumnsDateStateAgent(updatedColumnsAgent);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowColumn(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNavAgent = (
    // parentId: string | null, userTypes: string
  ) => {
    // console.log(parentId, "parentIdparentId");
    // if (parentId) {
    //   navigate(`/add-agent/${parentId}/${userTypes}`);
    // } else {
      navigate(`/add-agent`);
    // }
  };
  const handleNavUser = (
    // parentId: string | null, userTypes: string
  ) => {
    // if (parentId) {
    //   navigate(`/add-user/${parentId}/${userTypes}`);
    // } else {
      navigate(`/add-user`);
    // }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          agentStatus={agentStatus}
          setUserId={setUserId}
          userDataBreadCrumb={userDataBreadCrumb}
          title={agent ? "User List" : "All User List"}
          pageTitle="Dashboard"
          back
        />
        <Card>
          <Card.Body>
            <Row className="align-items-center">
              <Col lg={6}></Col>
              <Col
                lg={6}
                className="text-end mt-3 mt-lg-0 d-flex justify-content-center justify-content-md-end"
              >
                <Button
                  variant="outline-primary"
                  className="me-2"
                  onClick={() =>
                    handleNavUser()
                  }
                >
                  <i className="fs-lg ri-user-line align-middle me-1"></i> User
                </Button>
                {Number(user_type_id) !== 2 && (
                  <Button
                    variant="primary"
                    className="me-2"
                    onClick={() =>
                      handleNavAgent()
                    }
                  >
                    <i className="fs-lg ri-group-line align-middle me-1"></i>{" "}
                    Agent
                  </Button>
                )}
                <div ref={dropdownRef} style={{ position: "relative" }}>
                  <Button
                    variant="dark"
                    className="me-2"
                    onClick={() => setShowColumn(!showColumn)}
                  >
                    <i className="fs-lg  ri-settings-3-line align-middle me-1"></i>
                    <span className="edit-columns">
                    Edit Columns
                    </span>
                  </Button>
                  {showColumn && (
                    <ul
                      style={{
                        position: "absolute",
                        listStyle: "none",
                        background: "#fff",
                        zIndex: 9999,
                        boxShadow: "0 5px 10px rgba(30, 32, 37, 0.12)",
                        margin: 0,
                        padding: 0,
                        top: "40px",
                        textAlign: "left",
                        width: "170px",
                      }}
                    >
                      {(agentStatus
                        ? columnsDateState
                        : columnsDateStateAgent
                      )?.map((item: any, index: number) => {
                        if (item.name === "No." || item.name === "Action" || item?.name === "Screenshot" || item?.name === "Credit")
                          return null;
                        return (
                          <li
                            key={index + item?.name}
                            className="border-bottom p-2 px-3"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <Form.Check
                              inline
                              label={item.name}
                              type="checkbox"
                              id={`checkbox-${item.name}`}
                              checked={item.show}
                              onChange={() => toggleColumnVisibility(item.name)}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
                <Dropdown drop="start">
                  <Dropdown.Toggle
                    variant="subtle-dark"
                    className="e-caret-hide"
                  >
                    <i className="fs-lg ri-download-2-line"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="border-bottom py-2"
                      onClick={handleDownloadPdf}
                    >
                      <i className="fs-lg me-2 bi bi-file-earmark-pdf-fill align-middle"></i>{" "}
                      PDF
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="border-none py-2"
                      onClick={handleDownloadXml}
                    >
                      <i className="fs-lg me-2 bi bi-file-earmark-excel-fill align-middle"></i>{" "}
                      XLS
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            {agent ? (
              <UserListTableAgent
                setLimit={setLimit}
                setUserId={setUserId}
                getUserList={getUserListAgent}
                userData={userDataAgent}
                limit={limit}
                totalPages={totalResult}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalData={totalData}
                setFormValuesFilter={setFormValuesFilter}
                formValuesFilter={formValuesFilter}
                domainList={domainList}
                domainId={domainId}
                handleDomain={handleDomain}
                setUserData={setUserDataAgent}
                handleLevel={handleLevel}
                levelId={levelId}
                handleNavigate={handleNavigate}
                handleShowCRHistory={handleShowCRHistory}
                columnsDateStateAgent={columnsDateStateAgent}
                setIsLoading={setIsLoading}
              />
            ) : (
              <UserListTable
                limit={limit}
                totalResult={totalResult}
                setLimit={setLimit}
                setUserId={setUserId}
                getUserList={getUserList}
                userData={userData}
                totalPages={totalResult}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                totalData={totalData}
                setFormValuesFilter={setFormValuesFilter}
                formValuesFilter={formValuesFilter}
                domainList={domainList}
                domainId={domainId}
                handleDomain={handleDomain}
                handleLevel={handleLevel}
                levelId={levelId}
                setUserData={setUserData}
                handleNavigate={handleNavigate}
                handleShowCRHistory={handleShowCRHistory}
                columnsDateState={columnsDateState}
                setIsLoading={setIsLoading}
              />
            )}

            {isLoading && <Loader />}
          </Card.Body>
        </Card>
      </Container>

      <Modal
        size="lg"
        show={openCRHistory}
        onHide={() => setOpenCRHistory(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between">
          <div className="d-flex">
            <span>Credit Reference Logs - {userName}</span>
          </div>

          <Button
            variant="light btn-sm"
            onClick={() => setOpenCRHistory(false)}
          >
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="pt-1">
          <Table className="table-striped table-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th>Date</th>
                <th>From Name</th>
                <th>User Name</th>
                <th>Old Credit Reference</th>
                <th>New Credit Reference</th>
              </tr>
            </thead>
            <tbody>
              {CRHistoryData?.length !== 0 ? (
                CRHistoryData?.map((crData, id: number) => {
                  return (
                    <tr key={id}>
                      <td>
                        {moment(crData?.createdAt).format(
                          "DD-MM-YYYY, hh:mm:ss:SSS A"
                        )}
                      </td>
                      <td>{crData?.from}</td>
                      <td>{crData?.user_name}</td>
                      <td className="text-danger">
                        {crData?.old_credit_reference}
                      </td>
                      <td>{crData?.new_credit_reference}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className="text-center fw-bold">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserList;

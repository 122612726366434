import { FC, useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useParams } from "react-router-dom";

import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";
import FancyBook from "Common/Modals/FancyBook";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Marquee from "react-fast-marquee";
import ToggleSwitch from "Common/ToggleSwitch";
import FancyMarketSetting from "Common/Modals/FancyMarketSetting";
import { settingServices } from "Utils/setting/services";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const fancyCategory: Record<string, any> = {
  Fancy: {
    category: 0,
  },
  "Session Market": {
    category: 1,
  },
  "Over by Over Session Market": {
    category: 2,
  },
  "Ball by Ball Session Market": {
    category: 3,
  },
};

const FancyMarket: FC<Props> = ({
  previousData,
  getFancyData,
  toggleNews,
  combineFancyData,
  toggleMatchSetting,
  toggleBets,
  limitData,
  checkEventLimit,
  useridType,
  fancySheare,
  setFancySheare,
  fancyLabilaty,
  fancyLabilatySheare
}) => {

  const [showMarketFancy, setShowMarketFancy] = useState(false);
  const [matchedId, setMatchedId] = useState<string>();
  const [marketName, setMarketName] = useState<string>();
  const [category, setCategory] = useState<number>(0);
  const updateLoclstatus = async (is_lock: boolean, fancy_id: string) => {
    const { response } = await authServices.updateLimits({
      fancy_id,
      values: {
        is_lock,
      },
    });
    if (response?.status) {
      snackbarUtil.success(response.msg);
      getFancyData();
    } else {
      snackbarUtil.error(response.msg);
    }
  };
  const handleLock = (is_lock: boolean, fancy_id: string) => {
    updateLoclstatus(is_lock, fancy_id);
  };

  function convertToKandLForMax(number: number) {
    if (isNaN(number) || number == null) {
      return "0";
    }
    const sizeData = Number(number);
    const absNumber = Math.abs(sizeData);

    if (absNumber >= 10000000) {
      return (sizeData / 10000000).toFixed(1) + "Cr";
    } else if (absNumber >= 100000) {
      return (sizeData / 100000).toFixed(1) + "L";
    } else if (absNumber >= 1000) {
      return (sizeData / 1000).toFixed(1) + "K";
    } else {
      return sizeData.toString();
    }
  }

  const { id, sportid } = useParams();

  const [fancyData, setFancyData] = useState<
    { [key: string]: { category: number; data: FancyItem[] } } | undefined
  >();
  const [fancyDataPrev, setFancyDataPrev] = useState<
    { [key: string]: { category: number; data: FancyItem[] } } | undefined
  >();
  // const [activeFancyId, setActiveFancyId] = useState<string | null>(null);
  const [fancyName, setFancyName] = useState<string>("");
  // const [inputs, setInputs] = useState<{ [key: string]: { category: string; chronology: string } }>({});
 
  const [fancyRunData, setFancyRunData] = useState<any>();
  const [showFancyBook, setFancyBook] = useState(false);
  const [showAbonded, setAbonded] = useState(false);
  const [showCategory, setcategoryShow] = useState(false);



  const toggleFancyBook = (id: string, name: string) => {
    setFancyName(name);
    getFancyRunTime(id);
    setFancyBook(true);
  };
  const getFancyRunTime = async (fancy_id: string) => {
    const { response } = await authServices.getFancyRunTime({
      fancy_id,
    });

    setFancyRunData(response?.data?.fancy_position);
  };



  useEffect(() => {
    const categorizedData = combineFancyData?.data?.reduce(
      (acc: any, item: any) => {
        const categoryKey = item.category;
        const fancyCategoryName =
          categoryKey === 0
            ? "Fancy"
            : combineFancyData?.fancy_category[categoryKey];

        if (!acc[fancyCategoryName]) {
          acc[fancyCategoryName] = {
            category: categoryKey,
            data: [],
          };
        }

        acc[fancyCategoryName].data.push(item);
        return acc;
      },
      {}
    );

    setFancyData(categorizedData);
    // eslint-disable-next-line
  }, [combineFancyData]);

  useEffect(() => {
    const categorizedData = previousData?.data?.reduce(
      (acc: any, item: any) => {
        const categoryKey = item.category;
        const fancyCategoryName =
          categoryKey === 0
            ? "Fancy"
            : previousData.fancy_category[categoryKey];

        if (!acc[fancyCategoryName]) {
          acc[fancyCategoryName] = {
            category: categoryKey,
            data: [],
          };
        }

        acc[fancyCategoryName].data.push(item);
        return acc;
      },
      {}
    );

    setFancyDataPrev(categorizedData);
  }, [previousData]);

  // const handleToggle = (fancy_id: string, name: string) => {
  //     setActiveFancyId(prevId => (prevId === fancy_id ? null : fancy_id));
  //     setFancyName(name)
  // };
  // const handleInputChange = (fancy_id: string, event: any) => {
  //     const { name, value } = event.target;
  //     setInputs(prev => ({
  //         ...prev,
  //         [fancy_id]: {
  //             ...prev[fancy_id],
  //             [name]: value
  //         }
  //     }));
  // };

  const updateFancyDetails = async (
    fancy_id: string,
    category?: string,
    chronology?: string
  ) => {
    try {
      const payload: {
        fancy_id: string;
        category?: string;
        chronology?: string;
      } = { fancy_id };

      if (category) {
        payload.category = category;
      }

      if (chronology) {
        payload.chronology = chronology;
      }
      const { response } = await authServices.updateFancy(payload);

      if (response?.status) {
        snackbarUtil.success("Category Updated Successfully...");
        // setInputs(prev => ({
        //     ...prev,
        //     [fancy_id]: { category: '', chronology: '' },
        // }));
        setcategoryShow(false);
        // setActiveFancyId(null);
      } else {
        snackbarUtil.error(response?.msg);
      }
    } catch (error) {
      console.error("Failed to update fancy", error);
      snackbarUtil.error("Update failed");
    }
  };
  // const handleUpdate = (fancy_id: string) => {
  //     const { category, chronology } = inputs[fancy_id] || {};
  //     updateFancyDetails(fancy_id, category, chronology);
  // };

  const toggleFancySetting = (
    id: string | undefined,
    name: string,
    index: number
  ) => {
    setShowMarketFancy(!showMarketFancy);
    setMatchedId(id);
    setMarketName(name);
    setCategory(index);
  };

  const [fancyID, setFancyId] = useState<string>("");
  const toggleAbonded = (id: string, name: string) => {
    setFancyId(id);
    setAbonded(!showAbonded);
    setFancyName(name);
  };

  const getSessionAbd = async (fancy_id: string) => {
    const { response } = await settingServices.getSessionAbd({
      fancy_id,
    });
    if (response?.status) {
      snackbarUtil.success(response?.msg);
      setAbonded(false);
    } else {
      snackbarUtil.error(response?.msg);
    }
  };

  const handleUpdateAbd = () => {
    getSessionAbd(fancyID);
  };

  function compareOdds(
    prevPrice: number | undefined,
    newPrice: number | undefined
  ) {
    if (!prevPrice || !newPrice) return "";
    return prevPrice < newPrice
      ? "back_blink"
      : prevPrice > newPrice
      ? "lay_blink"
      : "";
  }

  const [dragResult, setDragResult] = useState<any>(null);

  const getUpdateFancyOrder = async (data: any) => {
    const { response } = await authServices.getUpdateFancyOrder(data);

    if (response && response?.status) {
      snackbarUtil.success("Updated Successfully...");
    }
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;
    if (!destination) return;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    if (source?.droppableId !== destination?.droppableId) {
      setDragResult(result);
      if (result) {
        setcategoryShow(true);
      }
      return;
    }
    // if (result && destination) {
    //     const cta = destination?.index + 1
    //     updateFancyDetails(result?.draggableId, "", cta);
    // }
    if (source.droppableId !== destination.droppableId) return;
    const categoryKey = Object.keys(fancyData!)[parseInt(source.droppableId)];
    if (!categoryKey) return;
    const items = Array.from(fancyData![categoryKey]?.data);
    const isDraggingDown = destinationIndex > sourceIndex;
    const affectedItems = isDraggingDown
      ? items.slice(sourceIndex + 1, destinationIndex + 1)
      : items.slice(destinationIndex, sourceIndex);

    const collectedData = affectedItems.map((item, index) => ({
      fancy_id: item.fancy_id,
      chronology: isDraggingDown
        ? sourceIndex + 1 + index
        : destinationIndex + index + 2,
      category: item?.category,
    }));

    collectedData.push({
      fancy_id: result?.draggableId,
      chronology: destinationIndex + 1,
      category: result?.destination?.droppableId,
    });

    getUpdateFancyOrder(collectedData);

    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    setFancyData((prevData) => ({
      ...prevData,
      [categoryKey]: {
        ...prevData![categoryKey],
        data: items,
      },
    }));
  };

  const handleUpadetCategory = () => {
    if (dragResult) {
      const index = dragResult?.destination?.index + 1;
      updateFancyDetails(
        dragResult?.draggableId,
        dragResult?.destination?.droppableId,
        index
      );
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {fancyData !== undefined && (
        <DragDropContext onDragEnd={onDragEnd}>
          {fancyCategory &&
            Object.keys(fancyCategory)?.map((keys, index: number) => {
              return (
                <Card
                  style={{
                    minHeight: "150px",
                  }}
                  key={keys + index}
                  className="p-2 mb-2 ribbon-box border shadow-none"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className="d-flex align-items-center"
                      style={{ paddingLeft: "65px" }}
                    >
                      <div className="ribbon ribbon-primary ribbon-shape">
                        {keys}
                      </div>
                    </div>
                  </div>

                  <Droppable
                    droppableId={String(fancyCategory[keys]?.category)}
                  >
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="table-responsive"
                      >
                        <Table className="table-borderless table-striped align-middle table-nowrap mb-0 mt-1">
                          <thead className="table-light">
                            <tr>
                              <th
                                colSpan={isSmallScreen ? 0 : 2}
                                className={`pt-4 px-1 mt-2  ${
                                  isSmallScreen
                                    ? "d-flex align-items-center justify-content-between"
                                    : ""
                                }`}
                              >
                                <div className="d-flex align-items-center">
                                  <span>P/S</span>
                                  <ToggleSwitch
                                    On="S"
                                    Off="F"
                                    id={keys}
                                    className="ms-2"
                                    onChange={() =>
                                      setFancySheare(!fancySheare)
                                    }
                                    checked={fancySheare ? true : false}
                                  />
                                  {useridType === 0 && (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          {" "}
                                          Fancy-Setting{" "}
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="btn-ghost btn-sm p-0 ms-2"
                                        onClick={() =>
                                          toggleFancySetting(
                                            id,
                                            "match_id",
                                            index
                                          )
                                        }
                                      >
                                        <i className="ri-settings-4-line fs-xl"></i>
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                                </div>
                                <div
                                  className="d-flex d-xxl-none align-items-center justify-content-center"
                                  style={{ width: "109px" }}
                                >
                                  <div className="px-2 textLay">No</div>
                                  <div className="px-2 textBack">Yes</div>
                                </div>
                              </th>
                              <th
                                className={`py-2 px-1 ${
                                  isSmallScreen ? "d-none" : ""
                                }`}
                              >
                                <div className="d-flex align-items-center justify-content-center">
                                  <div className="px-2 textLay">No</div>
                                  <div className="px-2 textBack">Yes</div>
                                </div>
                              </th>
                              {!isSmallScreen && <th></th>}
                            </tr>
                          </thead>
                          <tbody>
                            {fancyData &&
                              fancyData[keys]?.data?.map(
                                (fancys, id: number) => {
                                  const prevData =
                                    fancyDataPrev &&
                                    fancyDataPrev[keys]?.data[id];
                                  const limit =
                                    limitData &&
                                    (checkEventLimit
                                      ? limitData["session"]?.[fancys?.fancy_id]
                                      : limitData?.[fancys?.fancy_id]);
                                  const liabilityKey = `${fancys?.fancy_id}_full`;
                                  const liabilityValue = fancySheare
                                    ? fancyLabilaty?.[fancys?.fancy_id]
                                    : fancyLabilatySheare?.[liabilityKey] ?? 0;
                                  return (
                                    <>
                                      <Draggable
                                        key={fancys?.fancy_name}
                                        draggableId={fancys?.fancy_id}
                                        index={id}
                                      >
                                        {(provided) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={fancys.fancy_id}
                                            className={`${
                                              isSmallScreen ? "d-block" : ""
                                            }`}
                                          >
                                            <td className="w-100 p-1 text-wrap">
                                              <strong className="text-wrap">
                                                {fancys?.fancy_name}
                                              </strong>
                                              <div className="d-flex align-items-center">
                                                <span className="text-muted me-2">
                                                  ({fancys?.chronology})
                                                </span>
                                                <span
                                                  className={`${
                                                    Number(liabilityValue) < 0
                                                      ? "text-danger"
                                                      : "text-secondary"
                                                  } cursor-pointer`}
                                                  onClick={() =>
                                                    toggleFancyBook(
                                                      fancys?.fancy_id,
                                                      fancys?.fancy_name
                                                    )
                                                  }
                                                >
                                                  {fancyLabilaty &&
                                                    liabilityValue?.toFixed(2)}
                                                </span>
                                              </div>
                                            </td>
                                            <td className={`p-1 ${isSmallScreen?"d-none":""}`}>
                                              <div className="text-center">
                                                {useridType === 0 && (
                                                  <div>
                                                    <Button
                                                      variant="btn-ghost btn-sm p-0"
                                                      onClick={() =>
                                                        toggleMatchSetting(
                                                          fancys?.fancy_id,
                                                          "fancy_id",
                                                          fancys?.fancy_name
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-settings-4-line fs-xl"></i>
                                                    </Button>

                                                    {/* <OverlayTrigger trigger="click" show={activeFancyId === fancys.fancy_id} placement="top" overlay={<Popover id="popover-positioned-top">
                                                                                            <Popover.Header as="h3" className='d-flex align-items-center'>Update Type <Button variant='light' className='btn-sm ms-auto' onClick={() => setActiveFancyId(null)}><i className="ri-close-line fs-xl align-middle"></i></Button></Popover.Header>
                                                                                            <Popover.Body>
                                                                                                <span className='text-muted fw-normal'>{fancyName} ({fancys?.category || 0})</span>
                                                                                                <div className="d-flex mt-3">
                                                                                                    <InputGroup>
                                                                                                        <Form.Control onChange={(e) => handleInputChange(fancys.fancy_id, e)} name="category" type="text" placeholder='Type' />
                                                                                                        <Form.Control onChange={(e) => handleInputChange(fancys.fancy_id, e)} name="chronology" type="text" placeholder='Order' />
                                                                                                        <Button variant='subtle-success' onClick={() => handleUpdate(fancys.fancy_id)}>U-C</Button>
                                                                                                    </InputGroup>
                                                                                                </div>
                                                                                            </Popover.Body>
                                                                                        </Popover>}>
                                                                                            <Button variant="btn-ghost btn-sm p-0 ms-2" onClick={() => handleToggle(fancys.fancy_id, fancys?.fancy_name)}><i className='bx bx-edit align-middle fs-xl'></i></Button>
                                                                                        </OverlayTrigger> */}

                                                    <Button
                                                      variant="btn-ghost btn-sm p-0 ms-2"
                                                      onClick={() =>
                                                        handleLock(
                                                          !fancys?.is_lock,
                                                          fancys.fancy_id
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className={`fs-xl ${
                                                          !fancys?.is_lock
                                                            ? "ri-lock-unlock-line"
                                                            : "ri-lock-line"
                                                        }`}
                                                      ></i>
                                                    </Button>
                                                    <Button
                                                      variant="btn-ghost btn-sm p-0 ms-2"
                                                      onClick={() =>
                                                        toggleAbonded(
                                                          fancys?.fancy_id,
                                                          fancys?.fancy_name
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-delete-bin-6-line fs-xl"></i>
                                                    </Button>
                                                  </div>
                                                )}

                                                <div>
                                                  <Button
                                                    className="badge-gradient-info btn-sm border-0"
                                                    onClick={() =>
                                                      toggleBets(
                                                        "fancy_id",
                                                        fancys.fancy_id,
                                                        fancys?.fancy_name
                                                      )
                                                    }
                                                  >
                                                    Bets
                                                  </Button>
                                                  {useridType === 0 && (
                                                    <Button
                                                      className="badge-gradient-info btn-sm border-0 ms-2"
                                                      onClick={() =>
                                                        toggleNews(
                                                          fancys.fancy_id,
                                                          "fancy_id",
                                                          "fancy"
                                                        )
                                                      }
                                                    >
                                                      News
                                                    </Button>
                                                  )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="p-1">
                                              <div className="oddsSection d-flex position-relative">
                                                <div
                                                  className={`oddBtn p-1 text-secondary lay ${compareOdds(
                                                    prevData?.LayPrice1,
                                                    fancys?.LayPrice1
                                                  )} lh-sm rounded text-center`}
                                                >
                                                  <strong className="fs-xs">
                                                    {fancys?.LayPrice1}
                                                  </strong>
                                                  <p className="mb-0 fs-2xs">
                                                    {fancys.LaySize1}
                                                  </p>
                                                </div>
                                                <div
                                                  className={`oddBtn p-1 text-secondary back ${compareOdds(
                                                    prevData?.BackPrice1,
                                                    fancys?.BackPrice1
                                                  )} lh-sm rounded text-center`}
                                                >
                                                  <strong className="fs-xs">
                                                    {fancys?.BackPrice1}
                                                  </strong>
                                                  <p className="mb-0 fs-2xs">
                                                    {fancys?.BackSize1}
                                                  </p>
                                                </div>
                                                {fancys?.GameStatus?.toLowerCase() !==
                                                  "active" &&
                                                  fancys?.GameStatus !== "" && (
                                                    <div className="position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-xs text-danger border-danger text-uppercase fw-bold">
                                                      {fancys?.GameStatus ||
                                                        "Suspended"}
                                                    </div>
                                                  )}
                                                {fancys?.is_lock && (
                                                  <div className="position-absolute w-100 h-100 border rounded-2 bg-white bg-opacity-75 d-flex align-items-center justify-content-center fs-xs text-danger border-danger text-uppercase fw-bold">
                                                    Lock
                                                  </div>
                                                )}
                                              </div>
                                            </td>
                                            <td
                                              className={`p-1 ${
                                                isSmallScreen ? "d-none" : ""
                                              }`}
                                            >
                                              <div className="fs-3xs">
                                                <div>
                                                  Min:{" "}
                                                  {useridType === 0
                                                    ? convertToKandLForMax(
                                                        limit?.session_min_stack ??
                                                          0
                                                      )
                                                    : convertToKandLForMax(
                                                        limitData["session"]
                                                          ?.session_min_stack ??
                                                          0
                                                      )}
                                                </div>
                                                <div>
                                                  Max:{" "}
                                                  {useridType === 0
                                                    ? convertToKandLForMax(
                                                        limit?.session_max_stack ??
                                                          0
                                                      )
                                                    : convertToKandLForMax(
                                                        limitData["session"]
                                                          ?.session_max_stack ??
                                                          0
                                                      )}
                                                </div>
                                              </div>
                                            </td>
                                            <td className="p-1 d-flex d-md-none w-100 justify-content-between align-items-center">
                                              <div className="fs-3xs d-flex justify-content-between">
                                                <div >
                                                  Min:{" "}
                                                  {useridType === 0
                                                    ? convertToKandLForMax(
                                                        limit?.session_min_stack ??
                                                          0
                                                      )
                                                    : convertToKandLForMax(
                                                        limitData["session"]
                                                          ?.session_min_stack ??
                                                          0
                                                      )}
                                                </div>
                                                <div className="px-2">
                                                  Max:{" "}
                                                  {useridType === 0
                                                    ? convertToKandLForMax(
                                                        limit?.session_max_stack ??
                                                          0
                                                      )
                                                    : convertToKandLForMax(
                                                        limitData["session"]
                                                          ?.session_max_stack ??
                                                          0
                                                      )}
                                                </div>
                                              </div>
                                              <div className="text-center">
                                                {useridType === 0 && (
                                                  <div>
                                                    <Button
                                                      variant="btn-ghost btn-sm p-0"
                                                      onClick={() =>
                                                        toggleMatchSetting(
                                                          fancys?.fancy_id,
                                                          "fancy_id",
                                                          fancys?.fancy_name
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-settings-4-line fs-xl"></i>
                                                    </Button>
                                                    <Button
                                                      variant="btn-ghost btn-sm p-0 ms-2"
                                                      onClick={() =>
                                                        handleLock(
                                                          !fancys?.is_lock,
                                                          fancys.fancy_id
                                                        )
                                                      }
                                                    >
                                                      <i
                                                        className={`fs-xl ${
                                                          !fancys?.is_lock
                                                            ? "ri-lock-unlock-line"
                                                            : "ri-lock-line"
                                                        }`}
                                                      ></i>
                                                    </Button>
                                                    <Button
                                                      variant="btn-ghost btn-sm p-0 ms-2"
                                                      onClick={() =>
                                                        toggleAbonded(
                                                          fancys?.fancy_id,
                                                          fancys?.fancy_name
                                                        )
                                                      }
                                                    >
                                                      <i className="ri-delete-bin-6-line fs-xl"></i>
                                                    </Button>
                                                  </div>
                                                )}
                                              </div>
                                              <div>
                                                <Button
                                                  className="badge-gradient-info btn-sm border-0"
                                                  onClick={() =>
                                                    toggleBets(
                                                      "fancy_id",
                                                      fancys.fancy_id,
                                                      fancys?.fancy_name
                                                    )
                                                  }
                                                >
                                                  Bets
                                                </Button>
                                                {useridType === 0 && (
                                                  <Button
                                                    className="badge-gradient-info btn-sm border-0 ms-2"
                                                    onClick={() =>
                                                      toggleNews(
                                                        fancys.fancy_id,
                                                        "fancy_id",
                                                        "fancy"
                                                      )
                                                    }
                                                  >
                                                    News
                                                  </Button>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>

                                      <tr>
                                        <td colSpan={4}>
                                          <Marquee speed={45}>
                                            {fancys?.news}
                                          </Marquee>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </Droppable>
                </Card>
              );
            })}
        </DragDropContext>
      )}

      <FancyBook
        fancyRunData={fancyRunData}
        fancyName={fancyName}
        show={showFancyBook}
        setFancyBook={setFancyBook}
      />
      <FancyMarketSetting
        category={category}
        marketName="Fancy Setting"
        setShowMarketFancy={setShowMarketFancy}
        id={matchedId}
        marketId={marketName}
        show={showMarketFancy}
      />

      <Modal
        show={showAbonded}
        onHide={() => setAbonded(false)}
        className="zoomIn"
        scrollable
        centered ={isSmallScreen}
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Fancy Abond ({fancyName})</span>
          <Button variant="light btn-sm" onClick={() => setAbonded(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">
            Are you sure want to <strong>Abond Fancy</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button className="ms-2" onClick={handleUpdateAbd}>
            Update
          </Button>
          <Button variant="light" onClick={() => setAbonded(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCategory}
        onHide={() => setcategoryShow(false)}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <span>Update Fancy Type</span>
          <Button variant="light btn-sm" onClick={() => setcategoryShow(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="fs-md">
          <p className="fs-lg">Are you sure want to Move Fancy category?</p>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button className="ms-2" onClick={handleUpadetCategory}>
            Update
          </Button>
          <Button variant="light" onClick={() => setcategoryShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FancyMarket;

import Loader from "Common/Loader";
import SelfSettingForm from "Common/SelfSettingForm";
import ToggleSwitch from "Common/ToggleSwitch";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Modal, Form, Tab, Card, Nav } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface SelfSettingModalProps {
  show: any;
  clickHandler: any;
  username: string;
  userIds: string;
}

interface SportSettingData {
  sport: string;
  name: string;
  sport_id: string;
  market_min_stack: number;
  market_max_stack: number;
  market_min_odds_rate: number;
  market_max_odds_rate: number;
  market_bet_delay: number;
  market_max_profit: number;
  market_advance_bet_stake: number;
  session_min_stack: number;
  session_max_stack: number;
  session_bet_delay: number;
  session_max_profit: number;
  _id: string;
  market_bookmaker_max_odds_rate: number;
  market_bookmaker_min_odds_rate: number;
}

const SelfSettingModal = ({
  show,
  clickHandler,
  username,
  userIds,
}: SelfSettingModalProps) => {
  const [activeTab, setActiveTab] = useState<string>("all");
  const [showSportStatus, setSportStatus] = useState<boolean>(false);
  const [sportSetting, setSportSetting] = useState<SportSettingData[]>([]);
  const [sportData, setSportData] = useState<SportSettingData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSportsLimits = async (id: string) => {
    setIsLoading(true);
    const { response } = await authServices.getUserLimits({ user_id: id });
    setIsLoading(false);
    if (response?.status) {
      setSportSetting(response?.data?.sports_settings || []);
      setSportStatus(response?.check_event_limit);
      if (response?.data?.sports_settings?.length > 0) {
        setActiveTab(response?.data?.sports_settings[0]?._id);
        setSportData(response?.data?.sports_settings[0]);
      }
    } else {
      setSportSetting([]);
    }
  };

  const updateCheckEvent = async (id: string, check: boolean) => {
    setIsLoading(true);
    const { response } = await authServices.getEventLock({
      user_id: id,
      check_event_limit: check,
    });
    setIsLoading(false);
    if (response?.status) {
      setSportStatus(check);
      clickHandler();
    } else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  const updateSportsLimit = async (id: string, sportsData: any[]) => {
    setIsLoading(true);
    const { response } = await authServices.getUserSettingUpdate({
      sport_id: id,
      sports_settings: sportsData,
    });
    setIsLoading(false);
    if (response?.status) {
        snackbarUtil.success(response?.msg);
      clickHandler();
    } else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  useEffect(() => {
    if (userIds && show) getSportsLimits(userIds);
  }, [userIds, show]);

  const toggleSportsBtn = () => {
    updateCheckEvent(userIds, !showSportStatus);
  };

  const handleClose = () => {
    clickHandler();
  };

  const handleNavClick = (event: React.MouseEvent<HTMLDivElement>) => {
    let id = event.currentTarget.getAttribute("data-rr-ui-event-key");
    let item = sportSetting.find((setting) => setting._id === id);
    setSportData(item);

    setActiveTab(
      event.currentTarget.getAttribute("data-rr-ui-event-key") || "all"
    );
  };

  const handleDataValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSportData(
      (prevStack) =>
        ({
          ...prevStack,
          [name]: Number(value),
        } as SportSettingData)
    );
  };

  function getChangedValues(
    oldData: SportSettingData,
    newData: SportSettingData
  ): Partial<SportSettingData> {
    const changes: Partial<SportSettingData> = {};

    for (const key in newData) {
      const typedKey = key as keyof SportSettingData;

      // Compare values, and if they differ, add them to `changes`
      if (newData[typedKey] !== oldData[typedKey]) {
        // Type assertion to ensure it matches the expected type
        changes[typedKey as keyof SportSettingData] = newData[typedKey] as any;
      }
    }

    return changes;
  }

  const submitHandler = () => {
    let id = activeTab;
    let sportPayload = [];
    let item = sportSetting.find((setting) => setting._id === id);
    let changes = item && sportData && getChangedValues(item, sportData);

    if (changes && Object.keys(changes).length > 0) {
        sportPayload.push(changes);
        sportData?.sport_id && updateSportsLimit(sportData?.sport_id, sportPayload);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      className="zoomIn"
      scrollable
    >
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <div className="d-flex align-items-center">
          <span className="me-2">User Sports Settings : {username}</span>
          <ToggleSwitch
            onChange={toggleSportsBtn}
            checked={showSportStatus ? true : false}
          />
        </div>

        <Button variant="light btn-sm" onClick={handleClose}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="fs-md">
        <Tab.Container
          defaultActiveKey={
            sportSetting?.length > 0 ? sportSetting[0]?._id : "all"
          }
          activeKey={activeTab}
        >
          <Nav
            variant="tabs"
            style={{minHeight: '40px'}}
            className="nav-tabs-custom rounded border-bottom-0 mx-0 flex-nowrap overflow-auto w-100"
          >
            {sportSetting.map((item) => (
              <Nav.Item>
                <Nav.Link
                  className="text-nowrap"
                  eventKey={item._id}
                  onClick={handleNavClick}
                >
                  {item.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="text-muted">
            {sportSetting.map((item) => (
              <Tab.Pane eventKey={item._id}>
                <SelfSettingForm
                  data={sportData}
                  handleDataValueChange={handleDataValueChange}
                />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer className="text-end border-top">
        <Button onClick={submitHandler}>Save</Button>
        <Button variant="subtle-dark" onClick={handleClose}>Cancel</Button>
      </Modal.Footer>
      {isLoading && <Loader />}
    </Modal>
  );
};

export default SelfSettingModal;

import StatementTable from "Common/Tables/StatementTable";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { authServices } from "Utils/auth/services";
import { currentDateTime, getStartTimeOfDay } from "Utils/datefilter";
import { useNavigationType, useParams } from "react-router-dom";
import { generateOptions } from "Utils/searchFilter";
import { debounce } from "lodash";
import Loader from "Common/Loader";

interface StatementProps {
  filter?: boolean;
  activeTab: string;
  isReport?: boolean;
}

const Statement = ({ filter, activeTab, isReport = false }: StatementProps) => {
  const { userid } = useParams();
  const currentDate = new Date();
  const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  const [statementData, setStatementData] = useState<StatementListData[]>([]);
  const [startDate, setStartDate] = useState<string | Date>(
    getStartTimeOfDay(sevenDaysAgo)
  );
  const [endDate, setEndDate] = useState<string | Date>(currentDateTime());
  const [statementType, setStatementType] = useState<string>("");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });
  const [usersData, setUsersData] = useState<SearchUsersListData[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userOptions = useMemo(
    () => generateOptions(usersData || [], "_id", "user_name"),
    [usersData]
  );

  const navigationType = useNavigationType();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    localStorage.setItem("statementStart", startDate.toString());
    localStorage.setItem("statementEnd", endDate.toString());
  }, [startDate, endDate]);

  const getUsresList = async (userName: string) => {
    const { response } = await authServices.getUserList({
      user_name: userName,
    });
    if (response?.data) {
      setUsersData(response?.data);
    } else {
      setUsersData([]);
    }
  };

  const fetchOptions = debounce(async (input: string) => {
    if (input.length >= 3) {
      getUsresList(input);
    }
  }, 300);

  useEffect(() => {
    fetchOptions(inputValue);
  }, [inputValue]);

  const getStatementList = async (filters: any = {}) => {
    setIsLoading(true);
    const { response } = await authServices.getStatementList({
      limit: limit,
      page: page,
      ...(userid && userid !== "null" && { user_id: userid }),
      ...filters,
    });
    setIsLoading(false);
    if (response?.data && response?.data?.length > 0) {
      setStatementData(response?.data[0]?.data);
      setListTotal({
        total:
          response?.data[0]?.metadata[0]?.total /
          response?.data[0]?.metadata[0]?.limit,
        page: response?.data[0]?.metadata[0]?.page,
      });
    } else {
      setStatementData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  useEffect(() => {
    let start =
      navigationType === "POP"
        ? localStorage.getItem("statementStart")
        : startDate;
    let end =
      navigationType === "POP" ? localStorage.getItem("statementEnd") : endDate;
    start && setStartDate(new Date(start));
    end && setEndDate(new Date(end));

    if (activeTab === "Statement")
      getStatementList({
        ...(!isReport && {
          from_date: new Date(start || ''),
          to_date: new Date(end || ''),
        }),
      });
  }, [activeTab, limit]);

  const handlefilterSubmit = () => {
    let filterData: {
      from_date: string | Date;
      to_date: string | Date;
      statement_type?: string;
      user_id?: string;
      page:number;
    } = {
      from_date: startDate,
      to_date: endDate,
      page: 1,
    };
    if (statementType !== "") filterData.statement_type = statementType;
    if (selectedUser) filterData.user_id = selectedUser;
     
    setPage(1);
    getStatementList(filterData);
  };

  const handleClearSelection = () => {
    getStatementList({
      ...(!isReport && {
        from_date: getStartTimeOfDay(sevenDaysAgo),
        to_date: currentDateTime(),
      }),
      page: 1,
    });
    setPage(1);
    setStartDate(getStartTimeOfDay(sevenDaysAgo));
    setEndDate(currentDateTime());
    setStatementType("");
    setUsersData([]);
    setSelectedUser("");
  };

  const handleStatementType = (type: string) => {
    let filterData: {
      from_date?: string | Date;
      to_date?: string | Date;
      statement_type?: string;
      user_id?: string;
      page: number;
    } = {
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      page: 1,
    };
    if (type !== "") filterData.statement_type = type;
    if (selectedUser) filterData.user_id = selectedUser;
    
    setPage(1);
    setStatementType(type);
    getStatementList(filterData);
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    let filterData: {
      from_date?: string | Date;
      to_date?: string | Date;
      page: number;
      limit: number;
      statement_type?: string;
      user_id?: string;
    } = {
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      page: page,
      limit: limit,
    };
    if (statementType !== "") filterData.statement_type = statementType;
    if (selectedUser) filterData.user_id = selectedUser;

    getStatementList(filterData);
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <div className="d-flex align-items-center flex-wrap mb-3">
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Statement"
                    defaultChecked={statementType === ""}
                    onChange={() =>
                      statementType !== "" && handleStatementType("")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Statement">
                    Statement
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="FreeChips"
                    defaultChecked={statementType === "1"}
                    onChange={() =>
                      statementType !== "1" &&
                      handleStatementType(statementType === "1" ? "" : "1")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="FreeChips">
                    Free Chips
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="ProfitLoss"
                    defaultChecked={statementType === "2"}
                    onChange={() =>
                      statementType !== "2" &&
                      handleStatementType(statementType === "2" ? "" : "2")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="ProfitLoss">
                    Profit Loss
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Commission"
                    defaultChecked={statementType === "3"}
                    onChange={() =>
                      statementType !== "3" &&
                      handleStatementType(statementType === "3" ? "" : "3")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Commission">
                    Commission
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Settlement"
                    defaultChecked={statementType === "4"}
                    onChange={() =>
                      statementType !== "4" &&
                      handleStatementType(statementType === "4" ? "" : "4")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Settlement">
                    Settlement
                  </Form.Label>
                </div>
              </div>
              <Row className="gy-3 align-items-end">
                <Col lg={9}>
                  <Row className="gap-3 gap-sm-0">
                    <Col lg={4}>
                      <Form.Label>Start Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        placeholder="Select Date"
                        options={{
                          enableTime: true,
                          dateFormat: "Y-m-d H:i:s",
                          defaultDate: [startDate],
                          maxDate: endDate,
                        }}
                        value={startDate}
                        onChange={([date]: [Date]) => {
                          setStartDate(new Date(date));
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Form.Label>End Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: true,
                          dateFormat: "Y-m-d H:i:s",
                          defaultDate: [endDate],
                          maxDate: currentDateTime(),
                        }}
                        value={endDate}
                        onChange={([date]: [Date]) => {
                          setEndDate(new Date(date));
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Form.Label>Search By Username</Form.Label>
                      <Select
                        isClearable
                        options={userOptions}
                        value={
                          userOptions.find(
                            (option: any) => option.value === selectedUser
                          ) || null
                        }
                        className="customSelect"
                        onInputChange={(input: any) => setInputValue(input?.trim()?.toLowerCase())}
                        onChange={(e: any) => {
                          if (e) {
                            setSelectedUser(e.value);
                          } else {
                            setSelectedUser("");
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleClearSelection()}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <StatementTable
            statementData={statementData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            startDate={startDate}
            endDate={endDate}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
      {isLoading && <Loader />}
    </>
  );
};

export default Statement;

import TableContainer from "Common/TableContainer";
import { Dispatch, FC, SetStateAction, useMemo, useState, } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { baseURL } from "Utils/baseUrl";

interface Props {
  bannerData: bannerPayload[]
  getDeleted: (slug: string) => Promise<void>
  setFormData: Dispatch<any>
  setUpdateBanner: Dispatch<SetStateAction<boolean>>
  setBannerDataUpdate: any
  setDelete: Dispatch<SetStateAction<boolean>>
  showDelete:boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
}

const BannerSettingTable: FC<Props> = ({setDelete,showDelete, bannerData, getDeleted, setFormData, setUpdateBanner, setBannerDataUpdate, setIsLoading }) => {
 
 
  const [showImage, setShowImage] = useState(false);
  const [bannerName, setBannerName] = useState("");
  const [deletedId, setDeletedId] = useState("");
  const toggleDelete = (id:string, name?:string) => {
    setDeletedId(id)
    if(name){
      setBannerName(name);
    }
    setDelete(!showDelete);
  };


  const handleDeleted = ()=>{
    setIsLoading(true)
    getDeleted(deletedId);
  }


  const handleUpdateBanner = (payload:any)=>{
    setUpdateBanner(true)
    setBannerDataUpdate({
      title:payload?.title,
      slug:payload?.slug,
    })
  }

const [imgUrl, setImgUrl] = useState<string>("");

  const getImageUrl = (url:any)=>{
    setShowImage(true);
    setImgUrl(url);
  }
 

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return cell?.row?.index + 1
        },
      },
      {
        header: "Title",
        accessorKey: "title",
        enableColumnFilter: false,
      },
      {
        header: "Slug",
        accessorKey: "slug",
        enableColumnFilter: false,
      },
      {
        header: "Image",
        accessorKey: "image",
        enableColumnFilter: false,
        cell: (cell: any) => {
          // const url = `${process.env.REACT_APP_API_BASEURL}/${cell?.row?.original?.description}`
          const url = `${baseURL}/${cell?.row?.original?.description}`
          return (
            <div onClick={()=>getImageUrl(url)}>
              <img className="cursor-pointer" src={url} alt="Image" style={{ maxWidth: '100px' }} />
            </div>
          );
        },
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Button className="btn-sm" variant="subtle-info" onClick={()=>handleUpdateBanner(cell?.row?.original)}><i className="fs-lg ri-edit-2-fill"></i></Button>
              <Button className="btn-sm ms-2" variant="subtle-danger" onClick={()=>toggleDelete(cell?.row?.original?.slug, cell?.row?.original?.title)}><i className="fs-lg ri-delete-bin-6-fill"></i></Button>
            </div>
          )
        },
      },
    ],
    [bannerData]
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={bannerData || []}
        isBordered={false}
        customPageSize={50}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />
      <Modal show={showDelete} onHide={()=>toggleDelete("")} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold">
          Delete Banner - {bannerName}
          <Button variant="light btn-sm" onClick={()=>toggleDelete("")}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to Delete Banner?</h4>
        </Modal.Body>
        <Modal.Footer className="border-top">
          <Button variant="danger" onClick={handleDeleted}>Delete</Button>
          <Button variant="subtle-dark" onClick={()=>toggleDelete("")}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showImage} onHide={()=>setShowImage(false)} className="zoomIn" scrollable>
        <Modal.Header className="modal-title fw-bold">
          Banner Image - {bannerName}
          <Button variant="light btn-sm" onClick={()=>setShowImage(false)}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <img src={imgUrl} style={{
            width:"100%"
          }} alt="banner_image"/>
        </Modal.Body>
        
      </Modal>
    </>
  );
};

export default BannerSettingTable;

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import avatar1 from "assets/images/avatar-1.jpg";
import { createSelector } from "reselect";
import DepositModal from "./Modals/DepositModal";
import WithdrawModal from "./Modals/WithdrawModal";
import SocketModal from "./Modals/SocketModal";
import EditStackModal from "./Modals/EditStackModal";
import RedisFlushModal from "./Modals/RedisFlushModal";
import SelfSettingModal from "./Modals/SelfSettingModal";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";

const ProfileDropdown = () => {
  const [userName, setUserName] = useState<any>("Admin");
  const [showDeposit, setDeposit] = useState<any>(false);
  const [showWithdraw, setWithdraw] = useState<any>(false);
  const [showSocket, setSocket] = useState<boolean>(false);
  const [showSelfSetting, setSelfSetting] = useState<boolean>(false);
  const [showStack, setStack] = useState<boolean>(false);
  const [showRedish, setRedish] = useState<boolean>(false);

  const selectUser = createSelector(
    (state: any) => state.Profile,
    (user) => user.user
  );
  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const toggleDeposit = () => {
    setDeposit(!showDeposit);
  };
  const toggleWithdraw = () => {
    setWithdraw(!showWithdraw);
  };
  const toggleSocket = () => {
    setSocket(!showSocket);
  };
  const toggleStack = () => {
    setStack(!showStack);
  };
  const toggleRedish = () => {
    setRedish(!showRedish);
  };
  const toggleSelfSetting = () => {
    setSelfSetting(!showSelfSetting);
  };
  const handleNavigate = (route: any) => {
    navigate(route);
  };
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const authUser: any = localStorage.getItem("authUser");
      const obj = JSON.parse(authUser);
      // setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user?.username ? user?.username : "Admin" : "Admin" || "Admin" :
      setUserName(
        process.env.REACT_APP_DEFAULTAUTH === "fake"
          ? obj.username === "Admin"
          : process.env.REACT_APP_DEFAULTAUTH === "firebase"
            ? obj.email && obj.email
            : "Admin"
      );
    }
  }, [userName, user]);

  const userDetails = localStorage.getItem("adminDetails");

  const userId = userDetails && JSON.parse(userDetails)?.user_name;
  const userTypeId = userDetails && JSON.parse(userDetails)?.user_type_id;
  const userData = localStorage.getItem("userId");

  const getUserList = async (filters: any = {}) => {
    if (!userId) return;
  };


  const getLogout = async()=>{
    const {response} = await authServices.getLogout();
    // console.log(response, "responseresponse")
  }


  const handleLogout = () => {
    getLogout();
  }

  return (
    <React.Fragment>
      <Dropdown className="header-item topbar-user">
        <Dropdown.Toggle
          type="button"
          className="btn bg-transparent border-0 arrow-none"
          id="page-header-user-dropdown"
        >
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={avatar1}
              alt="Header Avatar"
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userId}
              </span>
            </span>
            <i className="fs-lg ri-arrow-down-s-line text-muted"></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome Admin! </h6>
          <Dropdown.Item onClick={() => handleNavigate('/market-analysis')}>
            <i className="ri-bar-chart-2-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">My Market</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleNavigate('/reports/Statement')}>
            <i className="ri-file-list-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Statement</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleNavigate('/reports/Market')}>
            <i className="ri-arrow-up-down-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Profit Loss</span>
          </Dropdown.Item>
          {
            userTypeId === 0 && <>
              <Dropdown.Item onClick={toggleDeposit}>
                <i className="ri-currency-line text-muted fs-lg align-middle me-1"></i>{" "}
                <span className="align-middle">Deposit</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={toggleWithdraw}>
                <i className="ri-currency-line text-muted fs-lg align-middle me-1"></i>{" "}
                <span className="align-middle">Withdraw</span>
              </Dropdown.Item>
            </>
          }

          {/* <Dropdown.Item onClick={toggleSocket}>
            <i className="ri-shuffle-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Socket Status</span>
          </Dropdown.Item> */}
          <Dropdown.Item onClick={() => handleNavigate('/secure-auth')}>
            <i className="ri-shield-user-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Secure Auth Verification</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleNavigate('/login-history')}>
            <i className="ri-shuffle-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Login History</span>
          </Dropdown.Item>

          <div className="dropdown-divider"></div>
          <Dropdown.Item onClick={() => handleNavigate('/change-password')}>
            <i className="ri-lock-password-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Change Password</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={toggleStack}>
            <i className="ri-edit-box-line text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Edit Stack</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={toggleSelfSetting}>
            <i className="mdi mdi-cog-outline text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle">Self Settings</span>
          </Dropdown.Item>
          {
            userTypeId === 0 && <Dropdown.Item onClick={toggleRedish}>
              <i className="ri-delete-bin-3-line text-muted fs-lg align-middle me-1"></i>{" "}
              <span className="align-middle">Redis Flush</span>
            </Dropdown.Item>
          }

          {/* <Dropdown.Item href="#"><i className="mdi mdi-lock text-muted fs-lg align-middle me-1"></i> <span className="align-middle">Lock screen</span></Dropdown.Item> */}
          <Dropdown.Item onClick={handleLogout} >
            <i className="mdi mdi-logout text-muted fs-lg align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <DepositModal
        userIds={userData || ""}
        depositWithData={0}
        setDepositWithData={() => { }}
        getUserList={getUserList}
        userName={userId}
        show={showDeposit}
        clickHandler={toggleDeposit}
        userTypeId={userTypeId}
      />
      <WithdrawModal
        userIds={userData || ""}
        depositWithData={0}
        setDepositWithData={() => { }}
        getUserList={getUserList}
        userName={userId}
        show={showWithdraw}
        clickHandler={toggleWithdraw}
        userTypeId={userTypeId}
      />
      <SocketModal show={showSocket} clickHandler={toggleSocket} />
      <SelfSettingModal show={showSelfSetting} username={userId} userIds={userData || ""} clickHandler={toggleSelfSetting} />
      <EditStackModal show={showStack} clickHandler={toggleStack} />
      <RedisFlushModal show={showRedish} clickHandler={toggleRedish} />
    </React.Fragment>
  );
};

export default ProfileDropdown;

import { ListPagination } from "Common/ListPagination";
import TableContainer from "Common/TableContainer";
import TableEntry from "Common/TableEntry";
import { useMemo, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import snackbarUtil from "Utils/snackBarUtil";

interface Props {
  withdrawData: WithdrawData[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
}

const TotalWithdrawTable = ({
  withdrawData,
  page,
  limit,
  listTotal,
  handlePageFilter,
}: Props) => {
  // eslint-disable-next-line
  const [copied, setCopied] = useState(false);
  const [showSetting, setSetting] = useState(false);
  const [selectedMethod, setSelectedMethod] =
    useState<WithdrawPaymentDetail | null>(null);

  const handleCopy = () => {
    setCopied(true);
    snackbarUtil.success("Copy Successfull");
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const toggleSetting = (data?: WithdrawPaymentDetail[]) => {
    setSetting(!showSetting);
    setSelectedMethod(data ? data[0] : null);
  };

  function formatDateTime(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  const deposit = useMemo(() => {
    if (!withdrawData || !Array.isArray(withdrawData)) {
      return [];
    }
    return withdrawData.map((items, index: number) => ({
      id: items?._id,
      no: (page - 1) * limit + index + 1,
      name: items?.name,
      parentName: items?.parent_user_name,
      username: items?.user_name,
      amount: items?.amount,
      create: formatDateTime(items?.created_at),
      update: formatDateTime(items?.updatedAt),
      method: items?.payment_deatails[0]?.method_name,
      remark: items?.remark,
      mobile: items?.mobile,
      status: items?.status,
      payment: items?.payment_deatails,
    }));
  }, [withdrawData, page, limit]);

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "no",
        enableColumnFilter: false,
      },
      {
        header: "Username",
        accessorKey: "username",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <>
              <span>{cell?.row?.original?.username}</span>
              <CopyToClipboard
                text={cell?.row?.original?.username}
                onCopy={handleCopy}
              >
                <i className="ms-1 align-middle ri-file-copy-line cursor-pointer"></i>
              </CopyToClipboard>
            </>
          );
        },
      },
      {
        header: "Parent Name",
        accessorKey: "parentName",
        enableColumnFilter: false,
      },
      {
        header: "Amount",
        accessorKey: "amount",
        enableColumnFilter: false,
      },
      {
        header: "Payment Method",
        accessorKey: "method",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <span
              className={`text-${
                cell?.row?.original?.status === "REJECTED"
                  ? "danger"
                  : "secondary"
              }`}
            >
              {cell?.row?.original?.status}
            </span>
          );
        },
      },
      {
        header: "Remark",
        accessorKey: "remark",
        enableColumnFilter: false,
      },
      {
        header: "Request Time",
        accessorKey: "create",
        enableColumnFilter: false,
      },
      {
        header: "Updated Time",
        accessorKey: "update",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div>
              <Button
                className="btn-sm ms-2"
                variant="subtle-dark"
                onClick={() => toggleSetting(cell?.row?.original?.payment)}
              >
                <i className="fs-md align-middle ri-settings-4-line"></i>
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <TableContainer
        columns={columns || []}
        data={deposit || []}
        isBordered={false}
        customPageSize={limit}
        isPagination={false}
        tableClass="table-centered align-middle table-nowrap table-striped mb-3"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
      />

      <nav aria-label="Page navigation" className="px-3 d-flex">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(page, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal
        show={showSetting}
        onHide={toggleSetting}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold">
          Payment Details
          <Button variant="light btn-sm" onClick={() => toggleSetting()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center g-3">
            <Col lg={6}>Holder Name</Col>
            <Col lg={6}>{selectedMethod?.bank_holder_name}</Col>
          </Row>
          {selectedMethod?.method_name != "Bank" && (
            <Row className="align-items-center g-3">
              <Col lg={6}>UPI/PAYTM/ETC</Col>
              <Col lg={6}>{selectedMethod?.others}</Col>
            </Row>
          )}
          {selectedMethod?.method_name === "Bank" && (
            <>
              <Row className="align-items-center g-3">
                <Col lg={6}>Bank Name</Col>
                <Col lg={6}>{selectedMethod?.bank_name}</Col>
              </Row>
              <Row className="align-items-center g-3">
                <Col lg={6}>Ifsc Code</Col>
                <Col lg={6}>{selectedMethod?.ifsc_code}</Col>
              </Row>
              <Row className="align-items-center g-3">
                <Col lg={6}>Account No</Col>
                <Col lg={6}>{selectedMethod?.account_no}</Col>
              </Row>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TotalWithdrawTable;
